/**
 * 是否是iPad设备
 * @returns
 */
export function isIPad() {
  let userAgent = navigator.userAgent || "";
  userAgent = userAgent.toLowerCase();
  return userAgent && /ipad/i.test(userAgent);
}

/**
 * 是否是手机端设备
 * @returns
 */
export function isMobileDevice() {
  let userAgent = navigator.userAgent || "";
  userAgent = userAgent.toLowerCase();
  // 常见的移动设备标识
  const mobileKeywords = ["iphone", "android", "ipad", "ipod", "windows phone"];
  for (let i = 0; i < mobileKeywords.length; i++) {
    if (userAgent.indexOf(mobileKeywords[i]) !== -1) {
      return true;
    }
  }
  return false;
}

/**
 * 是否是触摸类型设备
 * @returns
 */
export function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

/**
 * 已适配移动端的路由列表
 */
const SuportMediaRoutes = ["blogentry"];

/**
 * 重置viewport
 * @param {String} routeName
 */
function resetViewport(routeName) {
  const viewport = document.querySelector("meta[name=viewport]");
  if (routeName && SuportMediaRoutes.includes(routeName)) {
    // 已适配移动端的路由，需要重置viewport
    viewport && viewport.setAttribute("content", "width=device-width, initial-scale=1.0");
  } else {
    const viewport = document.querySelector("meta[name=viewport]");
    viewport && viewport.setAttribute("content", "width=1200, initial-scale=1.0");
  }
}

export { SuportMediaRoutes, resetViewport };
