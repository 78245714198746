const zh = {
  common: {
    yes: "是",
    no: "否",
    days: "天",
    hours: "小时",
    minutes: "分钟",
    years: " 岁",
    more: "更多",
    all: "所有",
    ok: "确定",
    cancel: "取消",
    submit: "提交",
    skip: "跳过",
  },
  home: {
    first_title: "成为优秀的作家",
    description: "Storybird运用精美的插画启发孩子写故事并且帮助老师更轻松教学",
    description_1: "Storybird运用精美的插画启发孩子写故事并且帮助老师更轻松教学",
    description_2: "",
    get_started: {
      title: "开始使用",
      step_1: "步骤一",
      step_2: "步骤二",
      step_3: "步骤三",
      item_title_1: "选择一个插图",
      item_desc_1: "使用我们的一步一步的教程视频，轻松地获得最多的Storybird体验。",
      item_title_2: "写你的故事",
      item_desc_2: "使用我们的一步一步的教程视频，轻松地获得最多的Storybird体验。",
      item_title_3: "发布你的书本",
      item_desc_3: "使用我们的一步一步的教程视频，轻松地获得最多的Storybird体验。",
    },
    writing_features: "写作特色",
    all_illustration: "全球精美插画",
    illustration_number: "Storybird提供超过10000幅来自世界各地插画师的精美插画",
    writing_skills: "增进写作技巧",
    curriculum: "Storybird拥有超过700节由专业教育学者制作的进阶写作课程",
    publish_read: "发布与阅读",
    submit_stories: "发布你自己的故事书以得到专家的审核与评论，并且可以在Storybird中阅读海量书本",
    write_now: "开始写作",
    get_tarted: "开始使用",
    teaching_features: "教学特色",
    get_tarted_1: "新手引导",
    guide_text_1: "对于教师和学生，我们都拥有简单易懂的新手引导操作视频",
    class_management: "班级管理",
    class_management_text_1: "教师可以管理班级内的成员并且创作个性化作业",
    educational_resource: "教学资源",
    educational_resource_text_1: "我们提供所有教师可下载的图片和文档教学资源以提升教学效果",
    bring_storybird: "把Storybird带到你的学校",
    what_more: "更多功能",
    for_individuals: "个人用户",
    professional_moderator: "专业教师",
    user_community: "用户社区",
    for_homeschools: "家庭学校",
    writing_function: "写作功能",
    writing_courses: "教育课程",
    siginin: "登录",
    back: "返回",
    lang: "中文",
    you: "我的",
    classes: "班级",
    home: "首页",
    challenge: "挑战",
    read: "阅读",
    write: "写作",
    about: "关于",
    educators: "学校套餐",
    parents: "家庭套餐",
    pricing: "价格",
    membership: "升级会员",
    free: "开始免费试用",
    challenge_subtitle: "开始写作……是学习写作最好的办法。名家课程、小测验，写作训练，每月挑战。等你来写！",
    challenge_ready: "准备接招？ ",
    challenge_desc: "700臻选课程，专业作家手把手教你，解锁技巧，激扬文字。",
    writer_title: "全球精选作者",
    writer_desc:
      "在故事鸟，900万全球青少年作者，来自100多个国家。为你带来日日全新故事。发布你的故事，有机会得到来自欧美专业老师、出版社编辑和知名作者的专业点评。",
    picture_subtitle: "今日推荐",
    picture_from: "来自",
    picture_submissions: "作品",
    picture_desc: "全球1,002位绘图大师奉上自己的美图，只为了让你的故事无以伦比。",
    picture_tody: "（今日）",
    picture_book: "绘本",
    comic_subtitle: "蓝鸟和朋友们在线等。等你和全球作者一同创作漫画故事。或者，先看看这一个。",
    comic: "漫画故事",
    comic_from: "来自",
    comic_storybird: "篇漫画",
    longform_subtitle: "当你的故事足够精彩，而且长，你需要勇于把它写出来，给全世界看。",
    longform_title: "长篇故事",
    longform_stories: "篇长篇小说",
    flash_subtitle: "有时候，一站地铁或者一杯咖啡的时间，也能读到最好的故事。",
    flash_here: "这里是最“短小精湛”的故事。",
    flash_today: "今日  ",
    flash_fiction: "“微故事”",
    flash_fiction_storybird: "篇短篇小说",
    poetry_subtitle: "写一首诗，配上来自全球艺术家的精选美图，在诗文意境中抒发你的情感。",
    poetry_title: "诗歌",
    poetry_storybird: "篇诗歌",
    join: "加入Storybird，读全世界的好故事，写故事给全世界看。",
    signup: "免费注册",
    yourstuff: "我的创作",
    artshop: "我的艺术商店",
    Reading: "我正在读",
    Writing: "我正在写",
    Badges: "我的徽章",
    Crowns: "我的金币",
    Tickets: "审核券",
    Favorites: "收藏",
    BecomeMember: "升级会员",
    AccountInfo: "账号信息",
    FamilyPlan: "家庭套餐",
    SchoolPlan: "学校套餐",
    SignOut: "退出账号",
    more: "更多",
    referral_plan: "加入我们的用户推荐计划，获得特殊折扣优惠券！",
    account_setting: "账号设置",
  },
  login: {
    title: "Storybird账号登录",
    google_login: "Google登录",
    google_login_ad: "屏蔽广告的插件可能会阻拦登录",
    google_login_email: "你也可以使用下方的email登录",
    account: "用户名或邮箱",
    password: "密码",
    forgot: "忘记密码？",
    signin: "登录",
    policy: "隐私政策",
    service: "服务条款",
    help: "帮助",
    need_register: "Sorry! You need to register with Storybird before you can sign in.",
    sign_up: "注册",
    google_signin: "Google登录",
    classcode_signin: "班级码登录",
  },
  forgot: {
    password: "忘记密码",
    email: "邮箱",
    format: "错误的邮箱格式！",
    reset: "请输入账户的邮箱地址以重置账号",
    submit: "提交",
  },
  signup: {
    safe_title: "我们如何确保你的信息安全：",
    safe_item_1: "Storybird不需要儿童或学生的个人信息来创建或使用帐户。",
    safe_item_2: "我们使用最新科技手段对所有密码进行加密。",
    choose_date: "请输入有效日期",
    sorry: "很抱歉！",
  },
  footer: {
    about: "关于我们",
    service: "服务条款",
    policy: "隐私政策",
    guidelines: "社区和内容章程",
    faq: "帮助",
    updates: "更新",
    contact: "联系我们",
  },
  faq: {
    title: "常见问题",
  },
  vars: {
    ages: {
      all_ages: "所有年级",
      preschooler: "幼儿园",
      kid: "小学 低",
      tween: "小学 高",
      teen: "初中",
      young_adults: "高中",
    },
    subTypes: {
      scrip_screenplay: "分镜脚本",
      video_games: "视频游戏",
      youTube: "视频",
      strong_paragraphs: "段落描写",
      poem: "诗词",
    },
    sortArr: {
      new: "最新",
      new_authors: "新作者",
      popular_this_week: "一周TOP",
      popular_all_time: "热门作品",
      popular_this_month: "月度TOP",
      popular_this_year: "年度TOP",
    },
  },
  challenge: {
    BasicWriting: "基础写作挑战",
    CreativeWriting: "创意写作挑战",
    ThematicCompositions: "主题写作",
    ChildrenSongs: "儿童歌曲",
    LeveledReads: "阶梯阅读",
    HowtoGuides: "写作小提示",
  },
  read: {
    navs: {
      picture_book: "绘本故事",
      longform_story: "长篇故事",
      comics: "漫画故事",
      flash_fiction: "微故事",
      poetry: "诗歌",
      blog: "官方博客",
      poetry_write: "诗歌写作",
    },
    start_reading: "开始阅读",
    search_all_stories: "搜索全部",
    clear: "清空",
    filter: "筛选",
    heart: "爱心",
    use_this_art_to_creat: "用这幅作品创作",
    this_art_for_members_only: "这幅插图仅供会员使用。",
    cancel: "取消",
    upgrade: "升级会员",
    add_your_comment: "留言",
    add_yours: "留言",
    sorry: "抱歉！",
    you_must_be_logged: "你尚未登录，请先登录。",
    not_appropriate_for_your: "这篇作品不适合你的年龄，请选择其他符合你年龄的作品来开启阅读之旅吧！",
    only_visible_to_paid_members: "抱歉，开通会员才能阅读本书。",
    start_your_7_days: "开启7天的试用之旅或者成为会员才能阅读更多优质内容哦！",
    read_more_books: "查看适合我阅读的内容",
    start_now: "现在开启",
    start_here: "去登录",
    author: "作者",
    artist: "艺术家",
    expert_review: "专家评论",
    categories: "类型",
    my_Bookshelf: "我的书架",
    add_to_my_Bookshelf: "加入我的书架",
    remove_from_Bookshelf: "移出我的书架",
    for_easily_finding: "加入书架以便于方便地阅读你喜欢的作品哦！",
    THE_END: "完结",
    thanks_for_reading_start: "感谢阅读《",
    thanks_for_reading_end: "》",
    by: "by",
    read_it_again: "再读一次",
    or: "或",
    create_your_own: "我要创作",
    cover: "封面",
    end: "End",
    book_summary: "作品简介",
    no_summary: "作者没有为这个作品写简介哦。",
    more: "展开",
    less: "收起",
    aLL_COMMENTS: "所有留言",
    sort_oldest: "时间正序",
    sort_newest: "时间倒序",
    all_genre: "所有类型",
    no_stories_inconceivable: "没有找到任何作品吗？不可思议！",
    you_should_write_one: "如果你写一篇，一定能火！",
    hearts: "爱心",
    expressions: "表情",
    expert_review_1: "专家评价",
    comment: "评价",
  },
  write: {
    navs: {
      picture_book: "绘本故事",
      longform_story: "长篇故事",
      comics: "漫画故事",
      flash_fiction: "微故事",
      poetry: "诗歌",
      creative_writing: "创意写作",
    },
    select_artwork_start: "请选择素材开始你的写作",
    all: "全部",
    popular: "最热",
    recently_added: "最新",
    your_artwork: "你的素材",
    space_story: "太空故事",
    search_all_artwork: "搜索素材",
    my_uploads: "我的",
    images_created_by: "images created by",
    artists_from: "artists from",
    countries: "countries",
    animal_story: "动物故事",
    family_story: "家庭故事",
    holiday_story: "假期故事",
    spring_story: "春天故事",
    friend_story: "朋友故事",
    food_story: "没事故事",
  },
  notification: {
    title: "通知",
    view_more: "查看所有通知",
  },
  search: {
    all_type: "全部类型",
    Narrative: "记叙文",
    argument_and_opinion: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    _NA_: "无限定",
  },
  members: {
    Variety: "多元化",
    VarietyContent:
      "故事鸟拥有600多个课程和挑战，可以满足每个学生的需求。 简单明了的操作指南可帮助教师开设独特的课程。",
    Educational: "教育性",
    EducationalContent: "所有课程和挑战都符合通用教学标准，并附带工作表和扩展的资源清单。",
    Beautiful: "美观性",
    BeautifulContent: "学生将受到来自全球1000多位艺术家独特的艺术启发。",
    Expansive: "延伸性",
    ExpansiveContent: "喜爱创作的学员将能够将他们的故事鸟带到课堂之外，并在家也继续使用平台提高创作技能。",
    title:
      "故事鸟写作平台激发K-12的学生在写作中建立自信和创造力。故事鸟在全球拥有900万用户，已被引入80万个教室使用，是全球最大的语言艺术应用平台，同时为学校和老师提供丰富的教学资源。",
    georgia: "为什么选择故事鸟",
    unlock: "解锁创作天赋",
    ChooseMembership: "选择你的专属计划",
    INDIVIDUAL: "单个教师",
    MONTHLY: "月费会员",
    month: "月",
    monthly: "按月付费",
    price: "原价",
    SignIn: "注册",
    BuyNow: "购买",
    accounts: "仅供老师或学校帐户使用",
    PLAN: "年费会员",
    yearly: "年",
    membership: "学校/机构 年度套餐",
    SCHOOLPLAN: "学校和班级套餐",
    upTo: "节省",
    OFF: "OFF",
    Available: "适用于",
    members: "100-200人",
    ViewYourPlan: "查看学校套餐",
    DISTRICTPLAN: "学区套餐",
    Customizable: "专属定制",
    districts: "学校/机构 ",
    ApplyTo: "适用于",
    teachers: "200及以上师生",
    SchoolPlan: "故事鸟的学校套餐是学校享受会员优惠和记录每位学生写作的最佳和最优惠的选择。",
    CustomizedMembership: "没有找到想要的会员套餐？点击此处联系我们，获得为您定制的会员套餐！",
    Resources: "资源",
    TeacherNest: "教师角",
    Images: "图片",
    ViewMore: "查看更多",
    Info: "信息",
    ABOUT: "关于",
    Storybird: "故事鸟",
    uniqueLanguage:
      "是一个独特的语言艺术工具，专注于视觉故事。插画家原创艺术作品，我们的10,000多幅图片库激发年轻人创作绘本故事，漫画，长篇故事，诗歌和微故事。故事鸟提供600多个课程，测试和写作提示，由经验丰富的教育工作者和专家制作，与共同核心教育标准一致。",
    FUNDRAISERS: "故事鸟募捐项目",
    Fundraisers: "故事鸟募捐项目",
    literacy:
      "在不浪费宝贵的课堂时间的情况下提升读写能力和创造力。学生可以根据学校课程编写充满艺术灵感的书和诗歌。 只需点击一下，教师就可以为父母激活私人网上商店。学生创作精美的书籍，父母获得永久的回忆，教师筹集到所需的资金。 更多细节即将推出。",
    Links: "海外版资源",
    StorybirdHW: "故事鸟海外网站",
    Literacy: "故事鸟激发文学创作",
    ContactUs: "联系我们",
    Educators: "故事鸟教师/学校端",
    Channel: "故事鸟Youtbube 频道",
    Testimonials: "好评如潮",
    RecentTweets: "近期海外推文",
    right: "对！故事鸟做对了。",
    ISTE: "ISTE年度最佳教师",
    Changed: "改变了我的一些学习者从0到100的喜爱写作",
    Wellington: "5、6岁孩子的老师，惠灵顿新西兰州",
    MoreInformation: "更多信息",
    visit: "或者访问我们的",
    Blog: "博客",
    orOn: "或者",
    Facebook: "脸书",
    and: "和",
    Twitter: "推特",
    Copyright: "版权所有 Storybird Inc.",
    HowItWorks: "购买方式",
    individualAccount: "成为个人用户。",
    Upgrade: "选择家庭套餐，",
    familyPlan: "升级会员，人数5人。",
    Invite: "邀请家庭成员加入，",
    familyMembers: "开启专属于你和家人的创意写作旅程。",
    WhyStorybird: "选择故事鸟",
    libraryMemories: "家书抵万金。",
    Safe: "安全的",
    WorldClass: "故事鸟平台发布的所有内容都经由了遍布全球的专业团队的真人审核。保证孩子在安全的环境内阅读和写作。",
    Encouraging: "鼓励的",
    OurTeam: "专家团队对每一个故事给予积极的评论和反馈，鼓励孩子养成写作习惯。全球读者的鼓励和评论，让孩子信心倍增。",
    YoungWriters:
      "全球1000多名插画艺术家提供了10000多张精美插画，激发孩子的创作灵感，让孩子的每一个故事故事都值得收藏。",
    AllCourses: "所有课程名师讲解，基础和创意兼顾，更提供主题挑战以及写作小提示，全部对接美国权威教育标准。",
    COURSES: "适合家庭学习的课程",
    UNLOCKYOUR: "解锁全家的创作天赋",
    ChooseMembershipContinue: "选择“全家计划”，很划算！",
    INDIVIDUAL2: "个人用户",
    monthly2: "每月",
    MONTHLY2: "包月会员",
    NotAvailable: "不适用于教育工作者账号",
    FAMILYPLAN: "家庭套餐",
    familyMembership: "家庭套餐",
    familyMembership_1: "家庭计划最多包括六名家庭成员",
    familyMembership_2: "",
    members2: "5人",
    ViewYourPlan2: "查看家庭套餐",
    FamilyPlanMsg: "故事鸟家庭套餐，超值优惠价格，全家共享，开启创作专属于家庭的故事旅程。",
    oneOfKind:
      "独一无二的、以艺术激发创意的、适合儿童的学习平台，融合国际专业师资和出版社作家独家研发的写作课程。全平台通用。",
    FAQ: "查看更多",
    shop: {
      explorerBlocked: "由于你的支付弹窗被拦截了，请点击以下按钮，继续支付",
      ContinuePayment: "继续支付",
      agree: "我同意",
      Cancel: "取消",
      Thank: "谢谢购买!",
      PayFail: "支付失败!",
      paymentMethod: "支付方式选择",
      Check: "支票",
    },
    Unlock1: "加入会员",
    creative: "释放你的创造潜力",
    unlock_potential: "加入会员，释放你的创造潜力",
    SchoolPlan1: "Storybird暑期特惠！",
    JumpStart: "夏日会员计划让您乐享精彩假期！",
    NowOnly: "3个月的会员计划，现在仅需20美元！",
    dollar: "21",
    perUser: "美元！",
    TEACHERONLY: "单个教师",
    MONTHLYPLAN: "月费会员",
    billedMonthly: "按月付费",
    perMonth: "每月",
    notInclude: "不包含学生账户",
    annualfee: "29.99/年",
    members1: "家庭套餐",
    LearnMore: "了解更多e",
    ViewYourPlan1: "查看你的套餐",
    CLASSROOMAND: "班级和",
    SCHOOLPLAN1: "学校套餐",
    memberships: "适用于100-200人",
    teachersStudents: "老师和学生套餐",
    PriceBased: "根据人数定价",
    ViewPricing: "查看价格",
    schoolsDistricts: "200人以上的学校或学区请联系我们根据人数定价",
    membership1: "月费会员每月自动续订，可随时取消",
    purchase: "购买包年会员，一年只需$29.88，节省50%",
    Whyupgrade: "会员特权",
    AccessHundreds: "解锁400+写作课程和写作挑战，创作并发布故事，",
    yourWork: "同时还能获得专家1对1评阅，让孩子爱上写作，提高写作技能。",
    membersSay: "85%的用户说故事鸟激发了他们的",
    moreCreative: "创造力",
    membersSay1: "87%的用户说",
    moreSchool: "变得更加自信",
    StorybirdGiven: "故事鸟让他们在学校",
    sayStorybird: "75%的用户说使用故事鸟",
    improvedGrades: "提高了写作分数",
    theirIdeas: "，同时使他们学会更好地表达自己的想法。",
    NonMember: "非会员",
    FreeTrial: "试用期",
    Member: "正式会员",
    BENEFIT: "会员权益",
    SCHOOLPLAN2: "学校套餐",
    TeacherPlan: "老师套餐",
    Members: "Members",
    million: "9 million members. Used by more than 500,000 educators.",
    notAvailabe: "你已经在学校套餐中，不能购买月费会员",
    notAvailabe1: "你已经在学校套餐中，暂时还不能购买年费会员，当学校套餐过期之后，你才可以购买年费会员。",
    MonthlyMembership: "包月会员",
    accounts1:
      "请注意，教师专用计划不包括任何学生账户。您可以邀请40名学生和老师到您的班级进行免费试用，但被邀请的成员将不包括在本计划内。",
    StorybirdMembership: "包年会员",
    membership2: "您会从月费会员成为年费会员",
    ChooseMembership1: "选择会员套餐",
  },
  district: {
    Select: "请选择",
    Kindergarten: "幼儿园/学前班",
    Elementary: "小学",
    MiddleSchool: "初中",
    HighSchool: "高中",
  },
  pay: {
    confirmation: "订单确认中",
    complete: "支付成功",
    failed: "失败",
    Status: "状态",
    Waiting: "待支付",
    Reviewing: "审核中",
    Returned: "被打回",
    ReturnReason: "打回原因",
    Order: "订单",
    Placed: "日期",
    Total: "金额",
    Item: "项目",
    Detail: "详情",
    Pay: "去支付",
    ConfirmOrder: "订单确认",
    OrderCompleted: "订单已完成",
  },
  accountNav: {
    General: "账号设置",
    notifications: "邮件通知",
    Orders: "订单列表",
  },
  account: {
    Account: "账号",
    Username: "登录名:",
    Email: "邮箱:",
    AccountType: "账号类型",
    Regular: "学生",
    Teacher: "教师",
    Artist: "艺术家",
    Member: "会员",
    Joined: "注册时间",
    DeleteAccount: "删除账号",
    Membership: "会员",
    FreeTrialEnds: "试用期至",
    CancelPlan: "取消订阅",
    Theme: "主题",
    Change: "更换",
    Password: "密码",
    ChangePassword: "修改密码",
    LinkGoogle: "链接到Google账号",
    LinkingYour: "绑定Google账号后可以直接使用Google账号登录.",
    Avatar: "头像",
    Country: "国家",
    UpdateAvatar: "修改头像",
    SaveChanges: "保存修改",
    Saved: "保存成功",
    RemoveSucessfully: "退出成功。",
    RenewOn: "月费会员, 过期时间",
    ExpiresOn: "年费会员, 过期时间",
    update_profile: "更新你的信息:",
    birth: "生日",
    choose_birthday: "选择你的生日",
    birth_information: "我们不会公开或分享你的生日信息。我们仅使用此信息向你展示Storybird上与年龄相对应的故事和课程",
    guardian: "父母或监护人的",
    email: "邮箱",
    incorrect_email: "错误的邮箱格式！",
    country: "国家",
    save: "保存",
    account_not_activated: "你的账号尚未激活",
    check_email: "请查收邮件激活账号",
    resend_email: "重新发送激活邮件",
    or: "或者",
    logout: "退出登录",
    account_actived: "账号已激活",
    referral_code: "推荐码",
    invalid_referral_code: "推荐码无效",
    referral_code_not_allow_yourself: "不能使用自己的推荐码",
  },
  yourstuff: {
    Badges: "我的徽章",
    start_writing: "快开始写作吧！",
    your_chanllenge: "你得到的挑战徽章",
    hello: "亲爱的",
    donot_have: "你还没有开始写作哦。",
    first_writing: "开启你的写作挑战吧",
    your_school_plan: "你的学校计划",
    recent_writing: "我正在写",
    your_story: "你的作品已经保存为草稿，现在继续写作完成它吧！",
    submit_review: "申请评审",
    cheers: "恭喜你已经完成了你的作品，现在来将它提交给老师评审吧！",
    under_review: "评审中",
    reviewing: "老师正在评审你的作品，请耐心等待！",
    edit: "请修改",
    continue_writing: "继续写作",
    not_meet_guidelines: "你的作品没有通过评审！",
    edit_1: "编辑",
    read: "阅读",
    go_to_course: "查看课程",
    nothing_in_progress: "你还没有自己的作品哦，现在开始创作吧！",
    progress: "学习进度",
    lesson: "课程",
    quiz: "测试",
    writing: "写作",
    recent_reads: "我正在读",
    nothing_in_progress_reading: "你还没有阅读记录哦，现在开始阅读吧！",
    start_reading: "开始阅读",
    continue_to: "继续",
    yourstuff: "个人中心",
    classes: "班级",
    art_shop: "艺术商店",
    tools: "工具箱",
    getting_started: "开始试用",
    dashboard: "摘要",
    tabs_reading: "阅读",
    tabs_badges: "徽章",
    crowns: "金币",
    tickets: "审核券",
    favorites: "收藏",
    table_tickets: "审核券",
    redeemed_successfully: "兑换成功",
    exceeded_limit: "很抱歉，你超出了本月兑换次数限制",
    no_package: "请重新选择需要购买的数量。",
    please_login: "你尚未登录，请先登录再购买。",
    earn_crowns: "Earn Crowns",
    daily_writing: "每日写作",
    complete_challenge: "完成挑战",
    earn_desc: "周六或者周日提交的故事，如果公开发表，你将获取20个金币 (每天仅限一个故事)",
    spend_crowns: "金币使用记录",
    redeem_for: "兑换审核券",
    _70_crowns: "-70 金币",
    _70_crowns_for_1: "70金币可以兑换1张审核券",
    limit_redemptions: "",
    _200_crowns: "－200 金币",
    _200_crowns_pdf: "200金币可以兑换1次PDF下载",
    redeem_here: "我要兑换",
    redeem_for_pdf: "兑换PDF下载次数",
    crowns_earned: "Crowns Earned",
    keep_writing: "Keep writing, publishing, and completing challenges to earn more Crowns on Storybird!",
    earn_5_day: "每日写作可以每天获得5金币",
    earn_10_crowns: "完成挑战课程或小测验可以获得10金币",
    book_approved: "公开发布故事",
    book_publick_5: "公开发布故事，每个月可以有5次机会获得10金币",
    double_crown_weekends: "Double Crown weekends!",
    daily_writing_1: "Daily Writing",
    complete_challenge_1: "Complete Challenge",
    published: "Published to Public Library",
    ticket: {
      your_tickets: "我的审核券",
      buy_more: "购买更多审核券",
      review: "审核券",
      recent_ticket: "近期审核券记录",
      get_more: "怎么获得?",
      membership: "会员权益",
      as_of_membership: "作为会员权益，你的审核券在每个月会自动增加至10张。",
      start_month_10: "每月增加至10张",
      featured_writer: "故事获得推荐",
      as_a_reward: "如果你的精彩故事在故事鸟Storybird获得首页推荐，你将获得一张免费审核券。",
      _1_ticket: "可获得 1 张免费审核券",
      redeem_crowns: "使用金币兑换",
      redeem_crowns_ticket: "你可以使用金币兑换审核券。",
      _70_crowns_ticket: "70个金币兑换1张审核券",
      redeem_here: "点击兑换",
      purchased: "如果需要更多的审核券，请点击",
      click_here: "这里",
    },
    more: "更多",
    read_obj: {
      recent: "我正在读",
      nothing: "你还没有阅读记录哦，现在开始阅读吧！",
      reading: "开始阅读",
    },
    pdfs: {
      created: "您还没有创建一个pdf！",
      create_one: "去创建一个！",
      flash_fiction: "微故事",
      picture_book: "绘本故事",
      longform_story: "长篇故事",
      poetry: "诗歌",
    },
    badges: {
      challenges_accepted: "获取中",
      continue_writing: "继续写作",
      challenges_completed: "已获取",
      go_to_course: "查看课程",
      read: "阅读",
      edit: "编辑",
      no_completed_challenges: "You haven't completed any Challenges yet!",
    },
    favorites_obj: {
      bookshelf: "我的书架",
      hearted: "我赞过的",
      picture_book: "绘本故事",
      longform_story: "长篇故事",
      comic: "漫画故事",
      flash_fiction: "微故事",
      poetry: "诗歌",
      creative_writing: "创意写作",
      favourite_illustrations: "赞过的画",
      details: "详情",
      empty_illustration:
        "You don't have any Favorite Illustrations yet. \n You can give likes to Illustrations and use them to create new books.",
      empty_bookshelf: "You don't have any books in your Bookshelf.",
      empty_hearted: "You don't have any Favorite Books yet.\n You can give likes to Books and read them at any time.",
    },
    how_to_guides: "Storybird How To Guides",
    _class: {
      withdraw: "撤回",
      edit: "编辑",
      join_class: "加入班级",
      accept_join: "接受邀请",
      not_join: "我不想加入",
      leave: "退出班级",
      enter_code: "请输入你的邀请码",
      assignments: "课程",
      submitted: "已提交的",
      featrued_books: "班级书本",
      member: "班级成员",
      student: "学生",
      teacher: "老师",
      enter_desc: "请输入你的邀请码，加入班级",
      enter_desc_1: "邀请码",
      confirm_leave: "确定退出班级",
      class_ended: "抱歉，该班级已经结束，更多详情请联系你的老师。",
      become_a_member: "，成为会员才能加入班级学习哦！",
      become_a_member_1: "开通会员",
      welcome_class: "欢迎加入班级",
      get_start: "现在开始你的写作任务吧。",
      start_writing: "开始写作",
      accept_join_1: "接受邀请...",
      sure_to_join: "你确定不加入这个班级吗？",
      donot_join: "我不想加入...",
      donot_join_1: "我不想加入",
      refused_join: "你已拒绝加入该班级。",
      incorrect_format: "邀请码格式错误",
      join_class_1: "加入班级...",
      join_successfully: "成功加入班级!",
      already_joined: "你已经加入该班级，请返回班级查看详情。",
      not_membership: "只有成为会员才能加入班级哦, 你确定要开通会员吗？",
      sure_to_leave: "你确定要退出班级吗？",
      leave_1: "退出班级...",
    },
    pop_account: {
      dashboard: "摘要",
      account: "账号管理",
      avatar: "更改头像",
      signout: "退出登录",
    },
    repassword: "两次输入的密码不一致",
    delete: {
      desc: "删除账号是永久性的，你的故事，个人信息等全部会被清除。",
      continue: "我非常清楚，请继续",
      cancel: "取消",
      password: "请输入你的密码",
      confirm_del: "确认删除",
    },
    avatar: {
      choose: "选择你喜欢的头像",
      represent: "头像将代表你在Storybird的形象",
      save: "保存头像",
      cancel: "取消",
    },
    pdf: {
      pages: "{page_num}页PDF",
      re_generate: "重新生成",
    },
    writing_obj: {
      drafts: "草稿",
      published: "发布",
      featured: "被推荐",
      nothing: "你还没有自己的作品哦，现在开始创作吧！",
      start: "开始写作",
      publish: "发表",
      edit: "编辑",
      make_pdf: "生成PDF",
      print: "打印",
      print_1: "打印",
      to_longform: "转换为长篇故事",
      to_draft: "转为草稿",
      to_private: "转为私密",
      remove: "从班级中移出",
      delete: "删除",
      remove_1: "Remove",
    },
    publish: {
      not_finished: "还差最后一步啦!",
      add_detail: "添加或检查故事详情",
      del_story: "删除作品",
      save_draft: "保存到草稿",
      come_back: "你可以稍后继续编辑。",
      finished: "我已经完成了，发布我的故事",
      publicly: "公开发布",
      review_ticket: "使用评审券提交评审并公开发布你的故事。",
      learn_more: "了解更多。",
      select_chapter: "请选择要提交的章节:",
      privately: "私密发布",
      visible: "你的故事只对你可见。",
      submit_teacher: "提交给老师",
      submit_teacher_1: "提交给老师：",
      visible_teacher: "你的故事只对你和你的老师可见。",
      paid_members: "仅会员可以使用这用发布方式哦，如果您还不是会员，请先开通会员。",
      change_apply: "以下修改将应用到此故事的所有章节",
      language: "语言",
      required: "必选",
      select_language: "请选择故事中使用的语言。",
      summary: "简介",
      optional: "可选",
      appears: "这将出现在你的作品中。",
      tags: "标签",
      separate: "多个标签之间以空格分隔",
      tags_help: "标签可以帮助你管理你的故事。",
      Categories: "类型",
      choose_2: "最多选择2个",
      submit_review: "提交评审",
      publish: "发布",
      dashboard: "去个人中心",
      back_edt: "返回继续编辑",
      policy: "隐私政策",
      service: "服务条款",
      help: "帮助",
      detail: "详情",
      book_detail: "故事详情",
      no_package: "请重新选择需要购买的数量。",
      publishing_member: "仅会员可以使用这用发布方式哦，确定要开通会员吗?",
      submitted_teacher: "你已经成功提交了《{bookTitle}》",
      published_teacher: "你已经修改提交了《{bookTitle}》",
      submitted_review: "你已经成功提交了《{bookTitle}》",
      published_privately: "你已经成功提交了《{bookTitle}》",
      pop_del: {
        del_story: "删除该作品",
        msg: "你确定删除该作品吗？本操作为永久删除，且该作品将不可找回。",
        cancel: "取消",
        delete: "删除",
        have_deleted: "你已删除<em>《{result}》</em>，该作品已从系统中删除。",
      },
    },
    print: {
      printable_books: "可打印的书",
      cart: "购物车",
      order: "打印订单",
    },
    profile_write_book: "开始写作",
    profile_print_book: "打印书本",
  },
  Classes: {
    All: "全部类型",
    Narrative: "记叙文",
    Argument: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    NA: "无限定",
    CLASSPASSCODE: "班级邀请码",
    Show: "显示",
    Hide: "隐藏",
    Showcase: "主页",
    Assignments: "数据中心",
    Submissions: "已提交的作文",
    Roster: "班级成员",
    settings: "班级设置",
    your_classes: "我的班级",
    compilation_works: "书本集打印",
    compilation_comics: "漫画集",
    compilation_poetry: "诗歌集",
    compilation_creator: "书本集生成器",
    compilation_create: "创建",
    compilation_your_compilation: "你的书本集",
    compilation_add_title: "添加标题",
    compilation_preview: "预览",
    compilation_comics_lastest: "你至少需要5本漫画来创建一个漫画集",
    compilation_poetry_lastest: "你至少需要5本诗歌来创建一个诗歌集",
    back: "返回",
    finish: "完成",
    no_compilation: "目前没有书本集",
    no_compilation_books: "孩子提交到班级Submission的书会显示在这里",
  },
  classAssignment: {
    BasicWriting: "基础写作",
    CreativeWriting: "创意写作",
    ThematicCompositions: "主题写作",
    Children: "儿童歌曲",
    LeveledReads: "阶梯阅读",
    Click: "点击选择你需要的课程，取消再次点击即可取消",
    set: "请设置结束日期",
    All: "全部类型",
    Narrative: "记叙文",
    Argument: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    NA: "无限定",
    Sorry: "非常抱歉！这节课仅限付费会员使用。现在升级成为故事鸟会员，解锁所有精彩挑战！",
    addAssignmentPlaceholder: "添加艺术作品地址链接(例如:https://www.storybird.com/comic-artwork?art_id=281)",
  },
  classSubmission: {
    We: "期待你的学生开始写作！",
  },
  classRoster: {
    Role: "角色",
    Email: "邮箱",
    Username: "用户名",
    Name: "姓名",
    InitPassword: "初始密码",
    Status: "状态",
    PRIMARY: "主要",
    TEACHER: "老师",
    STUDENT: "学生",
    Joined: "已加入",
    Waiting: "等待加入",
    Add: "添加学生",
    Choose: "选择一个课程",
    AddTeacher: "添加老师",
    Import: "导入",
    Reset: "重置密码",
    RemoveInvitation: "删除邀请",
    Leaveclass: "退出班级",
    RemoveFromClass: "移出班级",
    Sorry: "抱歉，你的会员已经过期，续费可以恢复班级功能。",
    Areyou: "你确定要将这个用户从你的班级中删除吗？",
    Removered: "删除",
    Remove: "删除本邀请",
    Quit: "离开",
    sure: "你确定要离开这个班级吗？",
    Cancel: "取消",
    ResetPassword: "你确定为这个学生重置密码吗？",
    permission: "权限不够",
    Tips: "学生在加入班级时自动加入学校计划，教师需要由管理员邀请加入学校计划。",
    SigninLink: {
      desction_1: "你的学生可以通过扫描二维码、点击链接登录或者班级邀请码来登录。",
      desction_2: "默认的登录密码是",
      displayQrcode: "显示二维码",
      scan_to_login: "学生可以扫描登录",
      copyLoginLink: "复制登录链接",
      clickToLogin: "学生打开此链接登录",
      loginClassCodeTitle: "班级邀请码",
      loginByClasscode: "学生通过邀请码登录",
      download: "下载",
      Copied: "已复制",
      LoginTitle: "欢迎来到{className}班级！",
      LoginTip: "点击你的名称进行登录",
      LoginPassword: "请输入你的密码",
    },
    AddStudent: {
      createTitle: "创建你的学生账号",
      description_1: "如果你的学生没有账号，你可以在这里为他们创建账号。",
      description_2: "他们可以通过扫描二维码或点击班级链接登录。",
      description_3: "同时你也可以通过",
      description_3_2: "你随时可以在“学生管理”模块添加更多学生。",
      or: "或者",
      email: "学生邮箱",
      classCode: "班级邀请码",
      inputPlaceHolder: "输入学生的名字和邮箱，使用逗号隔开，每个学生在一个新的行",
      generateAccounts: "创建账号",
      inputEmpty: "请输入你的学生名称和邮箱",
      generateSuccess: "创建账号成功",
      buy: "购买学校套餐",
      renew: "重新续费",
      planExpired: "您的学校套餐已过期。请重新续费。",
      requirePlan: "您需要购买或加入学校套餐",
      account_value_err: "输入的账号名不能包含逗号",
      inputError: "第{row}行学生信息输入异常",
      add_student_username_error: "第{row}行学生名称只能包含数字和字母",
      add_student_email_error: "第{row}行学生邮箱输入异常",
    },
    ClassCode: {
      title: "你的学生可以通过班级码来登录",
      desc_1: "他们可以凭借班级码",
      desc_2: "来完成登录，步骤如下:",
      desc_3: "进入登录页面，点击班级码登录按钮，进入班级码页面，然后输入班级码",
    },
    CheckMemberError_1: "对不起，您的会员已过期。请重新续费。",
    CheckMemberError_2: "对不起，您的免费试用已经过期了。今天就成为会员，解锁更多与故事鸟的乐趣。",
    CheckMemberError_plan_none: "请购买学校套餐，解锁更多与故事鸟的乐趣。",
    CheckMemberError_plan_expired: "对不起，您的学校套餐已过期。请重新续费。",
    ResetPasswordTitle: "重置{name}的密码为{password}",
    inputPassword: "请输入密码",
    passwordError: "密码必须为4位数字",
    ResetPasswordSuccess: "重置密码成功",
    ResetClassCode: "你确定要生成新的班级码吗？新的班级码生成后，二维码和登录链接都会一起更新。",
  },
  ClassList: {
    CreateAClass: "创建班级",
    JoinAClass: "加入班级",
    CreateClass: "创建班级",
    CreateDialog: {
      Description_1: "先创建一个班级，且允许你管理你的学生和工作。",
      Description_2: "",
      formTitle: "输入您的班级详细信息",
      className: "班级名称",
      classGrade: "年级",
      teacherName: "教师姓名",
      classEndDate: "班级结束日期",
      CreateClass: "创建班级",
      Step_1_title: "步骤一",
      Step_1_desc: "创建你的班级",
      Step_2_title: "步骤二",
      Step_2_desc_1: "创建你的",
      Step_2_desc_2: "学生账号",
      Step_3_title: "步骤三",
      Step_3_desc: "管理你的班级",
      ClassComplete: "你的班级已创建完成",
      CompleteDesc_1: "现在把登录链接分享给你的学生。",
      CompleteDesc_2: "默认的登录密码是",
      CompleteDesc_3: "，他们可以在登录后更改密码。",
      CompleteButtonText: "完成",
    },
  },
  classSettings: {
    ClassSettings: "班级设置",
    ClassName: "班级名称",
    Classgradelevel: "班级年级",
    ReadOtherBooks: "阅读其他人的公开的书本",
    AllownPublicPublish: "允许公开发布书本",
    Choose: "设置课程结束时间：",
    Youcan: "你可以随时修改这个日期",
    UpdateClass: "保存设置",
    Select: "请选择",
    Kindergarten: "幼儿园/学前班",
    1: "一年级",
    2: "二年级",
    3: "三年级",
    4: "四年级",
    5: "五年级",
    6: "六年级",
    7: "七年级",
    8: "八年级",
    9: "九年级",
    10: "十年级",
    11: "十一年级",
    12: "十二年级",
    College: "大学",
    Occupational: "在职",
    Other: "其他",
  },
  footerRight: {
    PrivacyPolicy: "隐私政策",
    Terms: "服务条款",
    Help: "帮助",
  },
  PopClassShare: {
    ShareSettings: "分享链接",
    ShareYou: "你可以向你的家人和朋友展示你的班级主页",
    protects: "请为这个页面设置一个访问码来保护你班级中学生的隐私。",
    Updated: "更新访问码",
    ShareLink: "班级主页地址",
    CopyUrl: "复制链接",
    Copied: "已复制",
  },
  classCreate: {
    CreateClass: "创建一个班级",
    Start: "班级可以让你方便的管理学生，查看学生作文。",
    ClassName: "班级名字",
    ClassGrade: "年级",
    Primary: "任课老师名字",
    Choose: "设置课程结束时间：",
    You: "你可以随时修改这个日期",
    Create: "创建班级",
    Cancel: "取消",
  },
  joinClass: {
    Enter: "邀请码",
    ENTERTHECLASSPASSCODEHERE: "请输入你的邀请码",
    Join: "加入班级",
    Incorrect: "邀请码格式错误",
    Jointheclass: "加入班级...",
    JoinedSuccessfully: "成功加入班级!",
    HavBeenUsedUp: "该套餐已使用账号达到上限，请向你的老师反馈。",
  },

  planHead: {
    Sorry: "由于你的支付弹窗被拦截了，请点击以下按钮，继续支付",
    ContinuePayment: "Continue Payment",
    PayFail: "支付失败!",
    Thank: "谢谢购买!",
  },
  challenge_guides: {
    AllCategories: "教程分类",
    Plot: "情节",
    Structure: "结构",
    Character: "人物",
    Dialogue: "对话",
    Setting: "情境",
    Genre: "题材",
    Craft: "写作技巧",
    Vocabulary: "词汇表",
    Grammar: "语法",
    Reference: "参考资料",
    Search: "搜索",
    all_format: "全部作品类型",
    Comic: "漫画故事",
    FlashFiction: "微故事",
    PictureBook: "绘本故事",
    LongformChapter: "长篇故事",
    Poetry: "诗歌",
    AllGenres: "全部题材",
    Life: "生活",
    Humor: "幽默",
    Magic: "魔幻",
    Family: "家庭",
    Animals: "动物",
    Friends: "朋友",
    Mystery: "神秘",
    Romance: "浪漫",
    Thriller: "惊险",
    Adventure: "探险",
    SchoolLife: "学校生活",
    Fantasy: "科幻/玄幻",
    AllTopic: "关键话题",
    Pet: "宠物",
    Travel: "旅游",
    Food: "美食",
    Guides: "写作小提示",
    Build: "使用这些写作小提示，提高写作水平。",
    Format: "作品形式",
  },
  challenge_tag: {
    BasicWriting: "基础写作",
    CreativeWriting: "创意写作",
    ThematicCompositions: "主题写作",
    Children: "儿童歌曲",
    LeveledReads: "阶梯阅读",
    Guides: "写作小提示",
  },
  challenge_info: {
    All: "全部类型",
    Narrative: "记叙文",
    Argument: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    NA: "无限定",
  },
  challengeHome: {
    UNITS: "个单元",
    CHALLENGES: " 个挑战",
    PARTICIPATED: "个参与者",
    Units: "按单元学习",
    Recently: "最新",
    Featured: "推荐",
    Filter: "筛选",
    Clear: "清除筛选",
    WritingType: "写作类型",
    All: "全部",
    Narrative: "记叙文",
    Argument: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    NA: "无限定",
    AllCategories: "All Categories",
    BasicChallenges: "基础写作挑战",
    BasicChallengesDetails: "选择任务，激扬创意，开启创作旅程； 赢取徽章，以文会友，挥洒文学激情。",
    CreativeChallenges: "创意写作挑战",
    CreativeChallengesDetails: "选择任务，激扬创意，开启创作旅程； 赢取徽章，以文会友，挥洒文学激情。",
    ThematicCompositions: "主题写作",
    ThematicCompositionsDetails: "享受创作的机会与主题线索和季节性主题。",
    ChildrensSongs: "儿童歌曲",
    ChildrensSongsDetails: "为每天的歌词和奇迹歌唱。",
    LeveledReads: "阶梯阅读",
    LeveledReadsDetails: "阅读故事鸟精品图书，提升文学写作素养。",
  },
  challengeUnit: {
    All: "全部",
    Narrative: "记叙文",
    Argument: "议论文",
    Informational: "说明文",
    Correspondence: "新闻报道",
    Poetry: "诗歌",
    NA: "无限定",

    BasicWriting: "基础写作",
    CreativeWriting: "创意写作",
  },
  tools: {
    All: "所有故事",
    PictureBook: "绘本故事",
    LongformStory: "长篇故事",
  },
  printTtabs: {
    PrintableBooks: "可打印的书",
    Cart: "购物车",
    PrintOrder: "打印订单",
  },
  preOrder: {
    Alipay: "支付宝",
    Wechat: "微信支付:",
    FontImage: "请上传支票正面",
    Upload: "点击上传图片",
    BackImage: "请上传支票反面",
  },
  cart: {
    View: "查看购物车",
    Youhaven: "你还没有创作任何绘本故事或长篇故事哦，",
    startwritingtoday: "现在开始创作吧",
  },
  custome_challenge_cover: {
    Writing: "写作",
    NA: "无限定",
    View: "查看作业要求",
  },
  pop_assign_edit: {
    Delete: "删除",
    Edit: "编辑",
  },
  pop_print_book_type: {
    All: "所有故事",
    PictureBook: "绘本故事",
    LongformStory: "长篇故事",
  },
  pricing: {
    title: "解锁你的创造力",
    desc_1: "已经被数以百万计的学生，老师和写作者使用。",
    desc_2: "快来挑选属于你的会员计划吧！",
    compare: {
      free: "免费试用",
      basic_plan: "基本计划",
      tips_free: "阅读书籍",
      tips_individual: "每月帐单支付(随时取消)",
      tips_individual_yearly: "每年帐单支付",
      tips_school: "10个学生人数起，每年帐单支付",
      free_7_days: "7天试用",
      get_start: "开始试用",
      month_user: "/用户",
      year_user: "/用户",
      individual_plan: "个人计划",
      buy_now: "购买",
      monthly: "每月",
      yearly: "每年",
      school_plan: "学习计划",
      school_plan_price_desc: "特别折扣",
      best_deal: "最划算",
      core_features: "核心功能",
      access_book_you_created: "编辑您创建的书籍",
      need_buy_tickets: "需要额外购审核券",
      class_features: "班级功能",
      advanced_features: "进阶功能",
      with_artwork: "使用插画写作",
      publish_books: "发布你的书本",
      read_books: "阅读站内图书",
      access_guides: "新手引导",
      access_resources: "课程资源",
      challenges_to_class: "布置作业",
      review_submissions: "审阅作业",
      manage_membership: "管理班级",
      tract_performance: "追踪学生进度",
      create_custom_assignments: "创建自定义作业",
      edit_and_provide_feedback_on_students_work: "编辑和批改学生的作业",
      interact_with_community: "社区互动",
      access_custom: "个性化封面",
      featured_homepage: "有机会登上首页",
      review_tickets: "审核券和PDF下载",
      buy_family_plan: "想要购买家庭计划？请点击这里。",
      school_plan_free_trial: "免费试用 >>",
    },
    voice_title: "用户之声",
    voice: {
      erin_klein: {
        name: "艾琳·克莱因",
        about: "博客：Kleinspiration.com",
        text: "我认为我喜欢故事鸟，就像我的学生喜欢使用它一样。 网站上没有广告，它的体验超级友好，故事鸟非常吸引老师和学生。",
      },
      larry_ferlazzo: {
        name: "拉里·费拉佐",
        about: "老师，作家，博主",
        text: "用户可以选择特定艺术家的作品，然后创作文字来创建一个故事书…我将该网站列为学生可以在线写作的最佳场所",
      },
      shannon_miller: {
        name: "香农·米勒",
        about: "老师，图书管理员，教育技术顾问",
        text: "故事鸟可供所有年龄段的人使用，适用于所有不同的科目......学生可以重创经典......讲述科学概念或复述历史。",
      },
      amy_jussel: {
        name: "艾米·朱塞尔",
        about: "Shaping Youth创始人，执行董事",
        text: "我想不出有更好的方式鼓励文学探索，艺术趣味和新媒体技巧来激发一大批创意故事创作者。",
      },
      drew_buddie: {
        name: "德鲁·巴迪",
        about: "学习技术专家",
        text: "故事鸟太酷了。 是20年来我与学生一起使用的最吸引人的应用程序之一。",
      },
      john_mikulski: {
        name: "约翰·米库尔斯基",
        about: "中学助理校长",
        text: "故事鸟募捐活动与更传统的方式（销售巧克力棒，杂志订阅等）之间的区别在于，通过故事鸟销售的产品是私人且有意义的。 它让学生与募捐者有了更紧密的联系。",
      },
    },
    questions: {
      title: "有任何疑问？",
      item_tilte_1: "学校计划和个人计划有什么区别？",
      item_desc_1: "学校计划可以给你几个账户，你可以给你所有的学生，而教师计划只包括一个没有学生的账户。",
      item_tilte_2: "你接受什么付款方式？",
      item_desc_2: "我们接受所有主要的信用卡和借记卡，以及Paypal。请联系我们讨论采购订单。",
      item_tilte_3: "我的数据是否安全？",
      item_desc_3:
        "我们发布的所有故事/评论都需要我们的审核团队进行审核。当学生的作品获得Storybird的批准后，他们的书将能够被分享和阅读。",
      has_more: "我还有更多问题！",
      email_us: "发送电子邮件至",
    },
  },
  buy: {
    title: "提交订单",
    title_renewal: "续订订单",
    number_limit: "*你不能购买比目前人数还少的学校计划",
    desc: "使用下面的工具查找包含批量折扣的价格",
    desc_renewal: "请选择账号数量，获取价格信息",
    next: "下一步",
    pre: "上一步",
    requirements: {
      title: "账号规定",
      desc: "拖动可选择人数（教师和学生总数）",
    },
    information: {
      title: "学校信息",
      basic_information: "基本信息",
      school_name: "学校名称",
      country: "国家",
      address: "地址",
      city: "城市",
      state: "州",
      zip: "邮政编码",
      phone: "电话号码",
      teaching_information: "教师信息",
      first_name: "名字",
      last_name: "姓氏",
      job_title: "职称",
      school_email: "学校邮箱",
      hear_about_us: "你是怎么知道我们的?",
      student_number: "学生人数",
      start_date: "试用激活日期",
    },
    form_rules: {
      name: "请输入学校名称",
      country: "请选择国家",
      address: "请输入地址",
      city: "请输入城市",
      state: "请输入州",
      zip: "请输入邮政编码",
      phone: "请输入您的电话号码",
      firstName: "请输入名字",
      lastName: "请输入姓氏",
      jobTitle: "请输入职称",
      schoolEmail: "请输入学校邮箱地址",
      hear: "请选择",
    },
    how_use: {
      title: "您将如何使用Storybird?",
      item_1: "小学",
      item_2: "初中",
      item_3: "高中",
      item_4: "第二语言",
      item_5: "远程学习",
      item_6: "语言艺术",
      item_7: "其他",
      talk_us: "您还想跟我们说什么?",
    },
    pay_order: {
      title: "支付",
      student_accounts: "学生账号数",
      original_price: "原价",
      discount: "折扣",
      total_price: "总价",
      buy_tips: "帐户包括学生帐户和教师帐户。例如，如果你有1名老师和10名学生，你需要购买11个账户。",
      buy_tips_1: "有效期一年",
    },
    buy_now: "立即购买",
    free_trial_title: "申请学校计划免费试用",
    free_trial_received: "我们已经收到你的申请，请等待我们的回复",
    free_trial_received_unregister: "我们已经收到你的申请，请等待我们的回复。激活邮件已发送，请于24小时内激活账号。",

    free_trial_apply: "申请",
  },
  top_bar: {
    guides: "新手指南",
    newBook: "创建故事",
    chooseBookType: "选择故事类型",
  },
  language: {
    all: "所有语言",
    english: "英语",
    chinese: "中文简体",
  },
};

export default zh;
