// 校验字符串是否为邮箱格式
export function isEmailFormat(str) {
  if (!str || str === "") {
    return false;
  }
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (reg.test(str.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}

// 是否不为空的字符串
export function isNotEmptyString(str) {
  if (str && str != "null" && str.length > 0) {
    return true;
  }
  return false;
}

// 是否是空字符串
export function isEmptyString(str) {
  if (!str || str === "") {
    return true;
  }
  return false;
}

// 替换字符串后面的空格
export function replaceStringTrailSpace(str) {
  if (!str || str.length === 0) {
    return str;
  }
  const originLength = str.length;
  let newString = str.replace(/(\s)+$/g, "");
  const newLength = newString.length;
  if (originLength === newLength) {
    return str;
  }
  for (let i = 0; i < originLength - newLength; i++) {
    newString += "&nbsp;";
  }
  return newString;
}

export function isEmptyObject(obj) {
  if (!obj) {
    return true;
  }
  return JSON.stringify(obj) === "{}";
}

// 获取URL中的文件名
export function getFileNameByUrl(url, includeExtension = false) {
  if (!url || url.length === 0) {
    return "";
  }
  const index = url.lastIndexOf("/");
  let filename = url.substring(index + 1);
  if (!includeExtension) {
    let extensionIndex = filename.lastIndexOf(".");
    filename = filename.substring(0, extensionIndex);
  }
  return filename;
}

// 将字母映射为数字0-9
export function letterToNumber(letter) {
  let charCode = letter.toUpperCase().charCodeAt(0);
  if (charCode >= 65 && charCode <= 90) {
    // A-Z
    return (charCode - 65) % 10; // 映射为 0-9 的数字
  }
  return -1; // 非字母返回 -1 或其他默认值
}

// 字符串映射为数字
export function convertStringToNumbers() {
  // let result = "";
  // for (let i = 0; i < input.length; i++) {
  //   let letter = input[i];
  //   let number = letterToNumber(letter);
  //   if (number !== -1) {
  //     result += number.toString();
  //   } else {
  //     // 非字母保持不变
  //     if (isNaN(letter)) {
  //       result += "0";
  //     } else {
  //       result += letter;
  //     }
  //   }
  // }
  // return result;
  return "1234";
}

// 获取随机字符串
export function randomString(length = 10) {
  let str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
  return result;
}

// 去掉html标签
export function removeHtmlTags(str) {
  if (!str) {
    return "";
  }
  return str
    .replace(/<\/p>/g, "\n")
    .replace(/<\/div>/g, "\n")
    .replace(/<[^>]*>/g, "")
    .replace(/\n+/g, "<br>");
}

// 修改数组元素位置
export function moveArrayElement(array = [], oldIndex, newIndex) {
  // 检查索引是否有效
  if (oldIndex < 0 || oldIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
    return array; // 返回原数组
  }

  // 取出要移动的元素
  const [element] = array.splice(oldIndex, 1);
  // 在新的位置插入元素
  array.splice(newIndex, 0, element);

  return array;
}

// 判断字符串是否包含特殊字符
export function containsSpecialCharacters(str) {
  const pattern = /[^a-zA-Z0-9]/; // 匹配非字母和数字的字符
  return pattern.test(str);
}

// 获取UUID
export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// diff字符串转JSON数组，方便Canvas处理
export function diffStringToJsonArray(str) {
  const jsonArray = [];
  const regex = /(<del.*?>(.*?)<\/del>|<ins.*?>(.*?)<\/ins>|([^<]+))/g;

  let match;
  while ((match = regex.exec(str)) !== null) {
    if (match[2]) {
      jsonArray.push({ type: "del", value: match[2] });
    } else if (match[3]) {
      jsonArray.push({ type: "ins", value: match[3] });
    } else if (match[4]) {
      jsonArray.push({ value: match[4] });
    }
  }

  return jsonArray;
}
