import { isMobileDevice } from "@/utils/platform.js";

const state = {
  originWidth: 0, // 设备原始宽度
  isMobile: false, // 是否是移动设备
  deviceSize: "large",
  devicePlatform: "web",
};

const mutations = {
  INIT_PLATFORM_DATA: (state, params) => {
    if (params) {
      state.originWidth = params.originWidth;
      state.isMobile = params.isMobile;
      state.deviceSize = params.screenSize;
      state.devicePlatform = params.platform;
    }
  },
};

const actions = {
  initViewport({ commit }) {
    return new Promise((resolve) => {
      const isMobile = isMobileDevice();
      const width = window.innerWidth;
      let screenSize = "larget";
      let platform = "web";
      if (width <= 768) {
        // 大屏幕不需要设置viewport
        screenSize = "small";
        platform = "mobile";
      } else if (width <= 1200) {
        screenSize = "medium";
        platform = "pad";
      }
      const params = {
        originWidth: width,
        isMobile: isMobile,
        screenSize: screenSize,
        platform: platform,
      };

      commit("INIT_PLATFORM_DATA", params);
      resolve({});
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
