const en = {
  common: {
    yes: "Yes",
    no: "No",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    years: " years old",
    more: "MORE",
    all: "All",
    ok: "OK",
    cancel: "Cancel",
    submit: "Submit",
    skip: "Skip",
  },
  home: {
    first_title: "Become a more effective writer!",
    description_1: "Empowering young writers to create unique stories with amazing illustrations. ",
    description_2: "Storybird’s art inspired writing helps engage students like never before!",
    get_started: {
      title: "Get started",
      step_1: "Step 1",
      step_2: "Step 2",
      step_3: "Step 3",
      item_title_1: "Choose an illustration",
      item_desc_1: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
      item_title_2: "Write your story",
      item_desc_2: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
      item_title_3: "Publish your book",
      item_desc_3: "Use our step by step tutorial videos to easily get the most out of your Storybird experience.",
    },
    writing_features: "Author’s tools",
    all_illustration: "Illustrations from artists around the globe",
    illustration_number:
      "Our one of a kind, carefully curated art gallery features over 10,000 stunning illustrations from artists and illustrators all over the world.",
    writing_skills: "Boost Writing Skills",
    curriculum:
      "Improve on the basics, learn new techniques, or explore a new style of writing with Storybird’s curriculum that includes over 700 guides, lessons, and challenges.",
    publish_read: "Share your stories",
    submit_stories:
      "Publish your stories to receive expert feedback from educators, professional editors, and other authors.",
    write_now: "Write Now",
    get_tarted: "Get Started",
    teaching_features: "Educator’s Features",
    get_tarted_1: "How-to-guides",
    guide_text_1:
      "Use our step by step tutorial videos to easily and instantly get the most out of your Storybird experience.",
    class_management: "Classroom Management",
    class_management_text_1:
      "Easily manage your class and add assignments using Storybird’s expansive curriculum, or create your own custom assignments.",
    educational_resource: "Additional Resources",
    educational_resource_text_1:
      "Expand your experience with downloadable support images and documents to improve teaching efficiency.",
    bring_storybird: "Bring Storybird to your Classroom",
    what_more: "But wait, there’s more!",
    for_individuals: "For Individuals and Families",
    professional_moderator: "Professional Moderation",
    user_community: "Safe and Friendly Community",
    for_homeschools: "For Homeschoolers",
    writing_function: "Unique writing formats and tools",
    writing_courses: "Language Arts Curriculum",
    siginin: "Sign in",
    back: "Back",
    lang: "English",
    you: "You",
    classes: "Classes",
    home: "Home",
    challenge: "Activities",
    read: "Read",
    write: "Write",
    about: "About",
    educators: "Educators",
    parents: "Parents",
    pricing: "pricing",
    membership: "Membership",
    free: "Sign up for FREE Trial",
    challenge_subtitle:
      "Write…now! Choose from hundreds of lessons, quizzes, and writing prompts for all ages, or take our monthly Challenge.",
    challenge_ready: "Ready for a Challenge?",
    challenge_desc:
      "Boost your writing skills with 700+ Challenges created by professional educators and expert authors.",
    writer_title: "Featured Writers",
    writer_desc:
      "9 million writers in more than 100 countries around the world use Storybird to tell their stories. Read the best writers, publish your work, and get expert feedback from teachers, professional editors, and authors.",
    picture_subtitle: "Today's Features",
    picture_from: "of",
    picture_submissions: "submissions",
    picture_desc:
      "Choose from more than 1,002 illustrations by professional artists to bring your amazing stories to life. Start here.",
    picture_tody: "Today's",
    picture_book: "Picture Book",
    comic_subtitle:
      "Bird and friends are waiting for you to write their adventure! Create your own Comic, or read some fan favorites.",
    comic: "Comic",
    comic_from: "One of",
    comic_storybird: "Comics on Storybird!",
    longform_subtitle:
      "When one chapter is not enough for the story you have to tell, show the world what you’ve got in longform. Challenge accepted.",
    longform_title: "Longform Story",
    longform_stories: "Longform stories on Storybird!",
    flash_subtitle: "Short and sweet. Sometimes the best stories are ones you can read in a single sitting.",
    flash_here: "Here are some winners.",
    flash_today: "Today's  ",
    flash_fiction: "Flash Fiction",
    flash_fiction_storybird: "Flash Fiction Stories on Storybird!",
    poetry_subtitle:
      "Express yourself with poetry! Add your own words and arrange them on artwork to create your own visual masterpiece.",
    poetry_title: "Poem",
    poetry_storybird: "Poems on Storybird!",
    join: "Join Storybird and start writing today.",
    signup: "Sign up for Free",
    yourstuff: "Your Stuff",
    artshop: "Your Art Shop",
    Reading: "Reading",
    Writing: "Writing",
    Badges: "Badges",
    Crowns: "Crowns",
    Tickets: "Tickets",
    Favorites: "Favorites",
    BecomeMember: "Become a Member",
    AccountInfo: "Account",
    FamilyPlan: "Family Plan",
    SchoolPlan: "School Plan",
    SignOut: "Sign Out",
    more: "more",
    referral_plan: "Join our user referral plan to get special discount coupons!",
    account_setting: "Account Setting",
  },
  login: {
    title: "Sign in to Storybird",
    google_login: "Google Sign In…",
    google_login_ad: "Ad blocking software may be blocking Google SignIn",
    google_login_email: "or sign in with email below",
    account: "Username or email address",
    password: "Password",
    forgot: "Forgot your password?",
    signin: "Sign In",
    policy: "Privacy Policy",
    service: "Terms of Service",
    help: "Help",
    need_register: "Sorry! You need to register with Storybird before you can sign in.",
    sign_up: "Sign Up",
    google_signin: "Sign in with Google",
    classcode_signin: "Sign in with CLASS CODE",
  },
  forgot: {
    password: "Forgot password",
    email: "Email",
    format: "Incorrect email format!",
    reset: "We'll send password reset instructions to this address.",
    submit: "Submit",
  },
  signup: {
    safe_title: "How we keep your information safe and secure:",
    safe_item_1:
      "Storybird does not require personal information from children or students in order to create or use an account.",
    safe_item_2: "We encrypt all passwords and stay up-to-date with all modern security methods.",
    choose_date: "Please choose a right date ",
    sorry: "Sorry! ",
  },
  footer: {
    about: "About",
    service: "Terms of Service",
    policy: "Privacy Policy",
    guidelines: "Guidelines",
    faq: "QA&Help",
    updates: "Updates",
    contact: "Contact Us",
  },
  faq: {
    title: "FAQ",
  },
  vars: {
    ages: {
      all_ages: "All Ages",
      preschooler: "Preschooler",
      kid: "Kid",
      tween: "Tween",
      teen: "Teen",
      young_adults: "Young Adults",
    },
    subTypes: {
      scrip_screenplay: "Scrip Screenplay",
      video_games: "Video Games",
      youTube: "YouTube",
      strong_paragraphs: "Strong Paragraphs",
      poem: "poem",
    },
    sortArr: {
      new: "New",
      new_authors: "New Authors",
      popular_this_week: "Popular this week",
      popular_all_time: "Popular all time",
      popular_this_month: "Popular this month",
      popular_this_year: "Popular this year",
    },
  },
  challenge: {
    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
    ThematicCompositions: "Thematic Compositions",
    ChildrenSongs: "Children's Songs",
    LeveledReads: "Leveled Reads",
    HowtoGuides: "How-to Guides",
  },
  read: {
    navs: {
      picture_book: "Picture Book",
      longform_story: "Longform Story",
      comics: "Comics",
      flash_fiction: "Flash Fiction",
      poetry: "Poetry",
      blog: "Blog",
      poetry_write: "Poetry",
    },
    start_reading: "Start reading",
    search_all_stories: "Search all stories",
    clear: "Clear",
    filter: "Filter",
    heart: "Heart",
    use_this_art_to_creat: "Use this art to creat",
    this_art_for_members_only: "This art for members only.",
    cancel: "Cancel",
    upgrade: "Upgrade",
    add_your_comment: "Add your comment",
    add_yours: "Add yours",
    sorry: "Sorry!",
    you_must_be_logged: "You must be logged in to access this. Please log in now or sign up for an account.",
    not_appropriate_for_your:
      "This content is not appropriate for your age level. Explore more stories for your age and start your adventure.",
    only_visible_to_paid_members: "Sorry, this story is only visible to paid members.",
    start_your_7_days: "Start your 7 days free trial or become a member today to unlock this story and more.",
    read_more_books: "Read more books",
    start_now: "Start now",
    start_here: "Start here",
    author: "Author",
    artist: "Artist",
    expert_review: "EXPERT REVIEW",
    categories: "Categories",
    my_Bookshelf: "My Bookshelf",
    add_to_my_Bookshelf: "Add to my Bookshelf",
    remove_from_Bookshelf: "Remove from Bookshelf",
    for_easily_finding: "For easily finding and reading your favorite stories.",
    THE_END: "THE END",
    thanks_for_reading_start: "Thanks for reading",
    thanks_for_reading_end: " ",
    by: "by",
    read_it_again: "Read it again",
    or: "or",
    create_your_own: "Create your own",
    cover: "Cover",
    end: "End",
    book_summary: "Book Summary",
    no_summary: "The author didn't write summary for this book.",
    more: "More",
    less: "Less",
    aLL_COMMENTS: "ALL COMMENTS",
    sort_oldest: "Sort: Oldest",
    sort_newest: "Sort: Newest",
    all_genre: "All Genre",
    no_stories_inconceivable: "No stories? Inconceivable!",
    you_should_write_one: "You should write one. It's sure to get noticed!",
    hearts: "Hearts",
    expressions: "Expressions",
    expert_review_1: "Expert Review",
    comment: "COMMENT",
  },
  write: {
    navs: {
      picture_book: "Picture Book",
      longform_story: "Longform Story",
      comics: "Comics",
      flash_fiction: "Flash Fiction",
      poetry: "Poetry",
      creative_writing: "Creative Writing",
    },
    select_artwork_start: "Select artwork to start writing",
    all: "All",
    popular: "Popular",
    recently_added: "Recently Added",
    your_artwork: "Your artwork",
    space_story: "Space Story",
    search_all_artwork: "Search all artwork",
    my_uploads: "My Uploads",
    images_created_by: "images created by",
    artists_from: "artists from",
    countries: "countries",
    animal_story: "Animal Stories",
    family_story: "Family Stories",
    holiday_story: "Holiday Stories",
    spring_story: "Spring Stories",
    friend_story: "Friend Stories",
    food_story: "Food Stories",
  },
  notification: {
    title: "Notifications",
    view_more: "See all notifications",
  },
  search: {
    all_type: "All",
    Narrative: "Narrative",
    argument_and_opinion: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    _NA_: "-NA-",
  },
  members: {
    Variety: "Variety",
    VarietyContent:
      "With more than 600 courses and challenges, Storybird has projects to fit every student’s needs. Simple, clear how-to guides help teachers build unique curriculums.",
    Educational: "Educational",
    EducationalContent:
      "All courses and challenges are aligned with common teaching standards and come with worksheets and expanded resource lists.",
    Beautiful: "Beautiful",
    BeautifulContent: "Students will be inspired by unique art from more than 1,000 global artists. ",
    Expansive: "Extensive",
    ExpansiveContent:
      "Students who fall in love with writing will be able to take their Storybird work beyond the classroom and continue to use the platform to improve their writing skills at home.",
    title:
      "Storybird’s tools inspire young people to build confidence and creativity in their writing. With 9 million members and 800,000 participating classrooms, Storybird is the world’s largest language arts program.Find resources for educators below.",
    georgia: "Why Storybird",
    unlock: "UNLOCK YOUR CREATIVE POTENTIAL.",
    ChooseMembership: "Choose a membership to continue:",
    INDIVIDUAL: "INDIVIDUAL",
    MONTHLY: "MONTHLY PLAN",
    month: "month",
    monthly: "billed monthly",
    price: "Original price",
    SignIn: "Sign In",
    BuyNow: "Buy Now",
    accounts: "Only available for educator accounts",
    PLAN: "YEARLY PLAN",
    yearly: "yearly",
    membership: "for yearly school membership",
    SCHOOLPLAN: "SCHOOL PLAN",
    upTo: "Up to",
    OFF: "OFF",
    Available: "Available for",
    members: "100-200 members",
    ViewYourPlan: "View your plan",
    DISTRICTPLAN: "DISTRICT PLAN",
    Customizable: "Customizable",
    districts: "schools and districts with 200",
    ApplyTo: "",
    teachers: "or more teachers and students.",
    SchoolPlan:
      "Storybird's School Plan is the best and most economical option for schools to enjoy the benefits of membership and chronicle each student's writing.",
    CustomizedMembership:
      "Don't see what you're looking for? Click here and contact us for a customized membership plan.",
    Resources: "Resources",
    TeacherNest: "The Teacher's Nest",
    Images: "Images",
    ViewMore: "View more FAQ",
    Info: "Info",
    ABOUT: "ABOUT",
    Storybird: "Storybird",
    uniqueLanguage:
      "is a unique language arts tool focused on visual storytelling. We curate original art from illustrators,and our library of 10,000+ images inspires young writers to create picture books, comics, longform chapter books, poetry, and flash fiction. Storybird’s writing curriculum offers 600+ lessons, quizzes, and writing prompts created by experienced educators and expert authors, aligned with Common Core educational standard.",
    FUNDRAISERS: "STORYBIRD FUNDRAISERS",
    Fundraisers: "Fundraisers",
    literacy:
      "promote literacy and creativity without wasting valuable classroom time. Students can write art-inspired books and poetry based on their school curriculum. Teachers can activate a private online shop for parents with one click. Students can create beautiful books, parents can receive lasting memories, and teachers can raise the funds they need. More details are coming soon.",
    Links: "Links",
    StorybirdHW: "Storybird",
    Literacy: "Storybird and Literacy",
    ContactUs: "Contact us",
    Educators: "Storybird Studio for Educators",
    Channel: "Storybird Youtube Channel",
    Testimonials: "Testimonials",
    RecentTweets: "Recent Tweets",
    right: "Yes! Storybird gets it right.",
    ISTE: "ISTE Teacher of the Year",
    Changed: "Changed some of my learners love of writing from 0 to 100.",
    Wellington: "Year 5/6 teacher, Wellington NZ",
    MoreInformation: "More information",
    visit: "or come visit us on our",
    Blog: "Blog",
    orOn: "or on",
    Facebook: "Facebook",
    and: "and",
    Twitter: "Twitter",
    Copyright: "Copyright Storybird Inc.",
    HowItWorks: "How It Works",
    individualAccount: "for an individual account.",
    Upgrade: "Upgrade",
    familyPlan: "to a family plan, available for 5 members.",
    Invite: "Invite",
    familyMembers: "family members to join and start your literary journey together.",
    WhyStorybird: "Why Storybird",
    libraryMemories: "A library of memories.",
    Safe: "Safe",
    WorldClass:
      "World-class moderators review everything created on the Storybird platform to ensure your children will write and read in a safe, supportive environment.",
    Encouraging: "Encouraging",
    OurTeam: "Our team of experts offers productive feedback on everything your child writes on Storybird.",
    YoungWriters: "Young writers can find inspiration in artwork from more than 1,000 artists.",
    AllCourses:
      "All courses are taught by professional teachers and authors and track to common educational standards.",
    COURSES: "COURSES AND CHALLENGES SUITABLE FOR FAMILY LEARNING.",
    UNLOCKYOUR: "UNLOCK YOUR CREATIVE POTENTIAL.",
    ChooseMembershipContinue: "Choose a membership to continue:",
    INDIVIDUAL2: "INDIVIDUAL",
    monthly2: "billed monthly",
    MONTHLY2: "MONTHLY PLAN",
    NotAvailable: "Not available for educator account",
    FAMILYPLAN: "FAMILY PLAN",
    familyMembership: "For yearly family membership",
    familyMembership_1: "Annual family plan includes up to",
    familyMembership_2: "six family members",
    members2: "5 children",
    ViewYourPlan2: "View your plan",
    FamilyPlanMsg: "Storybird's Family Plan is the best option for families to enjoy the benefits of membership.",
    oneOfKind:
      " A one-of-a-kind, state-of-the-art, safe learning community offering professional instruction across multiple hardware platforms.",
    FAQ: "View more FAQ",
    shop: {
      explorerBlocked:
        ' Sorry, your explorer blocked your payment, please click "Continue Payment" to complete the purchase.',
      ContinuePayment: "Continue Payment",
      agree: "I agree",
      Cancel: "Cancel",
      Thank: "Thank you for your payment!",
      PayFail: "Pay Fail",
      paymentMethod: "Please select payment method",
      Check: "Check",
    },
    Unlock1: "Unlock your",
    creative: "creative potential.",
    unlock_potential: "Unlock your creative potential",
    SchoolPlan1: "Storybird Summer Spectacular! ",
    JumpStart: "Beat boredom with our summer time plan!",
    NowOnly: "For a limited time only, get three months of Storybird for only $20!",
    dollar: "$21",
    perUser: "per user!",
    TEACHERONLY: "TEACHER ONLY",
    MONTHLYPLAN: "MONTHLY PLAN",
    billedMonthly: "billed monthly",
    perMonth: "per month",
    notInclude: "Does not include Student accounts",
    annualfee: "one-time annual fee $29.99",
    members1: "Starting at 2 members",
    LearnMore: "Learn more",
    ViewYourPlan1: "View your plan",
    CLASSROOMAND: "CLASSROOM AND",
    SCHOOLPLAN1: "SCHOOL PLAN",
    memberships: "memberships for up to 200",
    teachersStudents: "teachers and students",
    PriceBased: "Price based on number of users",
    ViewPricing: "View Pricing",
    schoolsDistricts: "for schools and districts with 200 or more teachers and students.",
    membership1: "Monthly plan membership renews automatically, and can be cancelled at any time.",
    purchase: "Save 50% when you purchase an annual membership for $29.99",
    Whyupgrade: "Why upgrade",
    AccessHundreds: "Access hundreds of writing lessons and prompts,",
    yourWork: "publish your work, and get expert feedback to help you improve.",
    membersSay: "85% of members say Storybird has made them",
    moreCreative: "more creative",
    membersSay1: "87% of members say",
    moreSchool: "more confidence at school",
    StorybirdGiven: "Storybird has given them",
    sayStorybird: "75% say Storybird",
    improvedGrades: "improved their grades",
    theirIdeas: "and helped them better shape and communicate their ideas",
    NonMember: "Non-Member",
    FreeTrial: "Free Trial",
    Member: "Member",
    BENEFIT: "BENEFIT",
    SCHOOLPLAN2: "School Plan",
    TeacherPlan: "Teacher Plan",
    Members: "Members",
    million: "9 million members. Used by more than 500,000 educators.",
    notAvailabe: "You're in School Plan, Monthly Plan is not availabe.",
    notAvailabe1:
      "You‘re in School Plan. Yearly Plan is currently not available and can be made when your School Plan expires.",

    MonthlyMembership: "Storybird Monthly Membership",
    accounts1:
      "Please note that the teacher only plan does not include any student accounts. You will be able to invite 40 students and teachers to your class for a free trial, but the invitees membership will not be included In this plan.",
    StorybirdMembership: "Storybird Annual Membership",
    membership2: "Your membership will be changed from monthly to annually.",
    ChooseMembership1: "Choose a membership to continue:",
  },

  district: {
    Select: "Select...",
    Kindergarten: "Kindergarten/Preschool",
    Elementary: "Elementary School",
    MiddleSchool: "Middle School",
    HighSchool: "High School",
  },
  pay: {
    confirmation: "Waiting for the order confirmation",
    complete: "Your payment is complete.",
    failed: "Your payment failed this time.",
    Status: "Status",
    Waiting: "Waiting for payment",
    Reviewing: "Reviewing",
    Returned: "Returned",
    ReturnReason: "Return reason",
    Order: "Order",
    Placed: "Placed",
    Total: "Total",
    Item: "Item",
    Detail: "Detail",
    Pay: "Pay",
    ConfirmOrder: "Confirm Order",
    OrderCompleted: "Order completed",
  },
  accountNav: {
    General: "General",
    notifications: "Email notifications",
    Orders: "Orders",
  },
  account: {
    Account: "Account",
    Username: "Username:",
    Email: "Email:",
    AccountType: "Account type",
    Regular: "Regular",
    Teacher: "Teacher",
    Artist: "Artist",
    Member: "Member",
    Joined: "Joined on",
    DeleteAccount: "Delete Account",
    Membership: "Membership",
    FreeTrialEnds: "Free trial ends",
    CancelPlan: "Cancel Plan",
    Theme: "Theme",
    Change: "Change",
    Password: "Password",
    ChangePassword: "Change Password",
    LinkGoogle: "Link Google",
    LinkingYour: "Linking your account will allow you to login using Google.",
    Avatar: "Avatar",
    Country: "Country",
    UpdateAvatar: "Update Avatar",
    SaveChanges: "Save Changes",
    Saved: "Saved",
    RemoveSucessfully: "Remove sucessfully.",
    RenewOn: "Renew on",
    ExpiresOn: "Expires on",
    update_profile: "Update your profile:",
    birth: "Date of Birth",
    choose_birthday: "Choose birthday",
    birth_information:
      "We will never publish or share your birth date. We only use this information to show you age-appropriate stories and lessons on Storybird.",
    guardian: "Parent/Guardian",
    email: "Email",
    incorrect_email: "Incorrect email format!",
    country: "Country",
    save: "Save",
    account_not_activated: "Your account has not been activated",
    check_email: "Please check your email to activate your account",
    resend_email: "Resend Email",
    or: "or",
    logout: "Logout",
    account_actived: "I have activated my account",
    referral_code: "Referral code",
    invalid_referral_code: "Invalid referral code",
    referral_code_not_allow_yourself: "You cannot use your own referral code",
  },
  yourstuff: {
    Badges: "Badges",
    start_writing: "Start writing today!",
    your_chanllenge: "Your Challenge Badges",
    hello: "Oh, hello",
    donot_have: "You don't have any writing activity yet.",
    first_writing: "Start your first writing challenge",
    your_school_plan: "Your School Plan",
    recent_writing: "Recent Writing",
    your_story: "Your story is saved as draft.Keep writing!",
    submit_review: "Submit for review",
    cheers: "Cheers! You finished your story, now submit to the experts to get review.",
    under_review: "Under review",
    reviewing: "Experts are reviewing your work, please be patient!",
    edit: "please edit",
    continue_writing: "Continue writing",
    not_meet_guidelines: "Sorry, your story does not meet our guidelines.",
    edit_1: "EDIT",
    read: "READ",
    go_to_course: "Go to the course",
    nothing_in_progress: "Nothing in progress. Start writing a story！",
    progress: "Progress",
    lesson: "Lesson",
    quiz: "Quiz",
    writing: "Writing",
    recent_reads: "Recent Reads",
    nothing_in_progress_reading: "Nothing in progress. Start reading a story！",
    start_reading: "Start reading today",
    continue_to: "Continue to",
    yourstuff: "Your Stuff",
    classes: "Classes",
    art_shop: "Art Shop",
    tools: "Tools",
    getting_started: "Getting Started",
    dashboard: "Dashboard",
    tabs_reading: "Reading",
    tabs_badges: "Badges",
    crowns: "Crowns",
    tickets: "Tickets",
    favorites: "Favorites",
    table_tickets: "Review Tickets",
    redeemed_successfully: "Redeemed successfully",
    exceeded_limit: "Sorry, you have exceeded the limit of redeem tickets this month.",
    no_package: "No this kind of package",
    please_login: "Please login first.",
    earn_crowns: "Earn Crowns",
    daily_writing: "Daily writing",
    complete_challenge: "Complete Challenge",
    earn_desc:
      "Earn 20 Crowns on stories submitted for review on Saturday and Sunday when they are approved for the public library. (Limit, one story each day)",
    spend_crowns: "Spend Crowns",
    redeem_for: "Redeem for review tickets",
    _70_crowns: "－70 Crowns",
    _70_crowns_for_1: "Redeem 70 Crowns for 1 Review Ticket!",
    limit_redemptions: "Limit of three redemptions per month.",
    _200_crowns: "－200 Crowns",
    _200_crowns_pdf: "Use 200 Crowns to redeem for 1 PDF download. ",
    redeem_here: "Redeem here",
    redeem_for_pdf: "Redeem for PDF downloads",
    crowns_earned: "Crowns Earned",
    keep_writing: "Keep writing, publishing, and completing challenges to earn more Crowns on Storybird!",
    earn_5_day: "Earn 5 Crowns for every day you come back and write! Can only be earned once a day.",
    earn_10_crowns: "Earn 10 Crowns when you successfully complete a lesson or a quiz.",
    book_approved: "Book approved for public publishing",
    book_publick_5:
      "Earn 10 Crowns when your book has been reviewed and approved for the public library. Maximum 5 entries per month.",
    double_crown_weekends: "Double Crown weekends!",
    daily_writing_1: "Daily Writing",
    complete_challenge_1: "Complete Challenge",
    published: "Published to Public Library",
    ticket: {
      your_tickets: "Your Tickets",
      buy_more: "Buy More Review Tickets",
      review: "Review Tickets",
      recent_ticket: "Recent Ticket Activity",
      get_more: "Get more tickets",
      membership: "Storybird Membership",
      as_of_membership:
        "As part of your Paid Membership, your account is automatically replenished to 10 Tickets each month.",
      start_month_10: "Start each month with 10 tickets.",
      featured_writer: "Featured Writer",
      as_a_reward:
        "As a reward for exceptional work, you will receive one Review Ticket any time one of your stories is featured on Storybird's homepage.",
      _1_ticket: "1 Ticket",
      redeem_crowns: "Redeem Crowns",
      redeem_crowns_ticket: "You may redeem crowns for Review Tickets.",
      _70_crowns_ticket: "70 crowns per ticket",
      redeem_here: "Redeem here",
      purchased: "Additional Review Tickets can be purchased ",
      click_here: "here.",
    },
    more: "More",
    read_obj: {
      recent: "Recent",
      nothing: "Nothing in progress. Start reading a story！",
      reading: "Start reading today",
    },
    pdfs: {
      created: "You haven’t created any PDFs yet!",
      create_one: "Create one now!",
      flash_fiction: "Flash Fiction",
      picture_book: "Picture Book",
      longform_story: "Longform Story",
      poetry: "Poetry",
    },
    badges: {
      challenges_accepted: "Challenges accepted",
      continue_writing: "Continue writing",
      challenges_completed: "Challenges Completed",
      go_to_course: "Go to the course",
      read: "Read",
      edit: "Edit",
      no_completed_challenges: "You haven't completed any Challenges yet!",
    },
    favorites_obj: {
      bookshelf: "Bookshelf",
      hearted: "Hearted",
      picture_book: "Picture Book",
      longform_story: "Longform Story",
      comic: "Comic",
      flash_fiction: "Flash Fiction",
      poetry: "Poetry",
      creative_writing: "Creative Writing",
      favourite_illustrations: "Favorite Illustrations",
      details: "Details",
      empty_illustration:
        "You don't have any Favorite Illustrations yet. \n You can give likes to Illustrations and use them to create new books.",
      empty_bookshelf: "You don't have any books in your Bookshelf.",
      empty_hearted: "You don't have any Favorite Books yet.\n You can give likes to Books and read them at any time.",
    },
    how_to_guides: "Storybird How To Guides",
    _class: {
      withdraw: "Withdraw",
      edit: "Edit",
      join_class: "Join the class",
      accept_join: "Accept and join",
      not_join: "I don't want to join",
      leave: "Leave the class",
      enter_code: "ENTER THE CLASS PASSCODE HERE",
      assignments: "Assignments",
      submitted: "Stories Submitted",
      featrued_books: "Class Books",
      member: "Class Member",
      student: "STUDENT",
      teacher: "TEACHER",
      enter_desc: "Enter the Class Passcode provided by your teacher to join the class.",
      enter_desc_1: "Enter the Class Passcode your teacher provided",
      confirm_leave: "Confirm and leave the class",
      class_ended: "Sorry, this class has already ended. Please contact your teacher for more information.",
      become_a_member: ", become a member today to join the class and start your journey with Storybird.",
      become_a_member_1: "Become a member",
      welcome_class: "Welcome to our class",
      get_start: "Let’s get started!",
      start_writing: "Start writing",
      accept_join_1: "Accept and join...",
      sure_to_join: "Are you sure you don't want to join the class?",
      donot_join: "I don't want to join...",
      donot_join_1: "I don't want to join",
      refused_join: "You have refused to join the class.",
      incorrect_format: "Incorrect class passcode format",
      join_class_1: "Join the class...",
      join_successfully: "Joined successfully!",
      already_joined: "You've already joined this class. To access the class, please go to your class list. ",
      not_membership:
        "Whoops, this class is not covered with Storybird School Plan membership. Individual Membership is needed to join this class.",
      sure_to_leave: "Are you sure you want to leave this class?",
      leave_1: "Leaving...",
    },
    pop_account: {
      dashboard: "Dashboard",
      account: "Account",
      avatar: "Update avatar",
      signout: "Sign Out",
    },
    repassword: "Passwords do not match",
    delete: {
      desc: 'Deleting your account is permanent. Your stories, connections, and profile will be lost. There is no "undo."',
      continue: "I understand, continue",
      cancel: "Cancel",
      password: "Please enter your password",
      confirm_del: "Delete Account",
    },
    avatar: {
      choose: "Choose an avatar",
      represent: "This avatar will represent you on Storybird.",
      save: "Use this Avatar",
      cancel: "Cancel",
    },
    pdf: {
      pages: "{page_num} page(s) PDF",
      re_generate: "Re-generate",
    },
    writing_obj: {
      drafts: "Drafts",
      published: "Published",
      featured: "Featured",
      nothing: "Nothing in progress. Start writing a story！",
      start: "Start writing today",
      publish: "Publish",
      edit: "Edit",
      make_pdf: "Make PDF",
      print: "Print",
      print_1: "Print",
      to_longform: "Convert to Longform Story",
      to_draft: "Move to Draft",
      to_private: "Make Private",
      remove: "Remove from Class",
      delete: "Delete",
      remove_1: "Remove",
    },
    publish: {
      not_finished: "You're not quite finished!",
      add_detail: "Add or review story details",
      del_story: "Delete Story",
      save_draft: "Save to draft.",
      come_back: "You can come back and work on it later.",
      finished: "I'm finished. Publish my story",
      publicly: "Publicly",
      review_ticket: "Use review ticket(s) to submit to the public library. ",
      learn_more: "Learn more.",
      select_chapter: "Please select the chapter(s) you would like to submit for review:",
      privately: "Privately",
      visible: "Your story will only be visible to you.",
      submit_teacher: "Submit to teacher(s) in my class",
      submit_teacher_1: "Submit to teacher(s) in my class:",
      visible_teacher: "Your story will only be visible to you and your teacher(s).",
      paid_members: "Submission to teacher(s) is available for free trial users and paid members.",
      change_apply: "Changes apply to all chapters in the book",
      language: "Language",
      required: "Required",
      select_language: "Select the language your story used.",
      summary: "Summary",
      optional: "Optional",
      appears: "This appears with your story.",
      tags: "Tags",
      separate: "Separate tags with a space.",
      tags_help: "Tags are how you organize stories.",
      Categories: "Categories",
      choose_2: "Choose a maximum of two options",
      submit_review: "Submit for Review",
      publish: "Publish",
      dashboard: "Go Dashboard",
      back_edt: "Or, go back and edit",
      policy: "Privacy Policy",
      service: "Terms of Service",
      help: "Help",
      detail: "Details",
      book_detail: "Book Details",
      no_package: "No this kind of package",
      publishing_member: "Publishing in this way is only available for free trial users and paid members.",
      submitted_teacher: "Your story <em>{bookTitle}</em> was submitted to your teacher.",
      published_teacher: "Your story <em>{bookTitle} was published {publishType}.",
      submitted_review: "Your story <em>{bookTitle}</em> was submitted successfully for review.",
      published_privately: "Your story <em>{bookTitle}</em> was successfully published privately.",
      pop_del: {
        del_story: "Delete this story",
        msg: "Are you sure you want to delete this story? This is permanent and non-reversible.",
        cancel: "Cancel",
        delete: "Delete",
        have_deleted: "You have deleted <em>{result}</em>. It has been removed from the system.",
      },
    },
    print: {
      printable_books: "Printable Books",
      cart: "Cart",
      order: "Print Order",
    },
    profile_write_book: "Write Your Book",
    profile_print_book: "Print Your Book",
  },
  Classes: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    CLASSPASSCODE: "CLASS PASSCODE",
    Show: "Show",
    Hide: "Hide",
    Showcase: "Showcase",
    Assignments: "Assignments",
    Submissions: "Submissions",
    Roster: "Roster",
    settings: "Class settings",
    your_classes: "Your Classes",
    compilation_works: "Compilation Works",
    compilation_comics: "Comics Compilation",
    compilation_poetry: "Poetry Compilation",
    compilation_creator: "Compilation Creator",
    compilation_create: "Create",
    compilation_your_compilation: "Your Compilation",
    compilation_add_title: "Add book's title here",
    compilation_preview: "Preview",
    compilation_comics_lastest: "You can assemble at least 5 Comics into a printable Compilation book.",
    compilation_poetry_lastest: "You can assemble at least 5 Poetry into a printable Compilation book.",
    back: "Back",
    finish: "Finish",
    no_compilation: "There is no Book Compilation now",
    no_compilation_books: "Books from Submission will show here",
  },
  classAssignment: {
    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
    ThematicCompositions: "Thematic Compositions",
    Children: "Children's Songs",
    LeveledReads: "Leveled Reads",
    Click: "Click the challenge to select. Click one more time to cancel.",
    set: "Please set a due date",
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    Sorry:
      "Sorry, this challenge is only visible for paid members. Upgrade to be a member of Storybird today to unlock all the challenges.",
    addAssignmentPlaceholder: "Add artwork url(E.g:https://www.storybird.com/comic-artwork?art_id=281)",
  },
  classSubmission: {
    We: "We can’t wait to see what your students write!",
  },
  classRoster: {
    Role: "Role",
    Email: "Email",
    Username: "Username",
    Name: "Name",
    InitPassword: "InitPassword",
    Status: "Status",
    PRIMARY: "PRIMARY",
    TEACHER: "TEACHER",
    STUDENT: "STUDENT",
    Joined: "Joined",
    Waiting: "Waiting for user",
    Add: "Add Student",
    Choose: "Choose a course:",
    AddTeacher: "Add Teacher",
    Import: "Import",
    Reset: "Reset Password",
    RemoveInvitation: "Remove Invitation",
    Leaveclass: "Leave class",
    RemoveFromClass: "Remove from class",
    Sorry: "Sorry, your membership has expired. Please renew to resume the class.",
    Areyou: "Are you sure you wish to remove this user from your class?",
    Removered: "Remove",
    Remove: "Remove this invitation?",
    Quit: "Quit",
    sure: "Are you sure you want to leave this class?",
    Cancel: "Cancel",
    ResetPassword: "Reset password for this student?",
    permission: "No permission",
    Tips: "Students join School Plan automatically when they join this class, Teachers need to be invited to join School Plan by Administrator.",
    SigninLink: {
      desction_1: "Your students can login by QR code, login link or class code,",
      desction_2: "and The default password is",
      displayQrcode: "Display QR code",
      scan_to_login: "Students scan to sign in",
      copyLoginLink: "Copy login link",
      clickToLogin: "Share the link to students",
      loginClassCodeTitle: "Class code",
      loginByClasscode: "Student sign in with class code",
      download: "download",
      Copied: "Copied",
      LoginTitle: "Welcome, {className} Class!",
      LoginTip: "Click your user name to login.",
      LoginPassword: "Please input your password",
    },
    AddStudent: {
      createTitle: "Create your student accounts",
      description_1: "If your students don’t have accounts you can generate accounts for them here.",
      description_2: "They can sign in by scanning a QR code or clicking on a class link.",
      description_3: "You can also add students via their",
      description_3_2: "You can add more students later on the roster.",
      or: "or",
      email: "email",
      classCode: "class code",
      inputPlaceHolder: "Type student's name and email, separated by commas, on a new line for each student",
      generateAccounts: "Generate accounts",
      inputEmpty: "Please input student's name and email",
      generateSuccess: "Generte success",
      buy: "Buy School Plan",
      renew: "Renew Plan",
      planExpired: "Your school plan has expired. Please renew to resume the class.",
      requirePlan: "In order to add students, you must be part of a school plan.",
      account_value_err: 'Accounts cannot contain ", "',
      inputError: "The information entered in line {row} is abnormal.",
      add_student_username_error: "The name entered in line {row} can contain only numbers and letters.",
      add_student_email_error: "The email entered in line {row} is abnormal.",
    },
    ClassCode: {
      title: "Your students sign in with CLASS CODE",
      desc_1: "They can sign in by entering the Class Passcode",
      desc_2: "through",
      desc_3: "“Sign in>Sign in with CLASS CODE>Sign in through password”",
    },
    CheckMemberError_1: "Sorry, your membership has expired. Please renew to resume the class.",
    CheckMemberError_2: "Sorry, your free trial has expired. Become a member today to unlock more fun with Storybird.",
    CheckMemberError_plan_none: "Become a school plan member today to unlock more fun with Storybird.",
    CheckMemberError_plan_expired: "Sorry, your school plan has expired. Please renew to resume the class.",
    ResetPasswordTitle: "Reset {name}'s password to {password}",
    inputPassword: "Please input password",
    passwordError: "The password must consist of four digits.",
    ResetPasswordSuccess: "Reset password success",
    ResetClassCode:
      "Are you sure to generate a new Class Code. After generating a new one, the QR code and Login link will also be regenerated.",
  },
  ClassList: {
    CreateAClass: "Create a class",
    JoinAClass: "Join a class",
    CreateClass: "Create Classes",
    CreateDialog: {
      Description_1: "Start by creating a class. A class enables you",
      Description_2: "to onboard students and review work.",
      formTitle: "Enter your class details",
      className: "Class name",
      classGrade: "Class grade level",
      teacherName: "Primary teacher's name",
      classEndDate: "Class end date",
      CreateClass: "Create class",
      Step_1_title: "STEP 1",
      Step_1_desc: "Create your class",
      Step_2_title: "STEP 2",
      Step_2_desc_1: "Create your",
      Step_2_desc_2: "student accounts",
      Step_3_title: "STEP 3",
      Step_3_desc: "Enjoy your class",
      ClassComplete: "Your class is complete",
      CompleteDesc_1: "Now share the login link to your students.",
      CompleteDesc_2: "The default password is",
      CompleteDesc_3: ", it can be changed after login.",
      CompleteButtonText: "Complete",
    },
  },
  classSettings: {
    ClassSettings: "Class Settings",
    ClassName: "Class name",
    Classgradelevel: "Class grade level",
    ReadOtherBooks: "Read other's books",
    AllownPublicPublish: "Publish their books online",
    Choose: "Choose an end date for this class:",
    Youcan: "You can change this date at any time.",
    UpdateClass: "Update Class",
    Select: "Select...",
    Kindergarten: "Kindergarten/Preschool",
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
    5: "5th",
    6: "6th",
    7: "7th",
    8: "8th",
    9: "9th",
    10: "10th",
    11: "11th",
    12: "12th",
    College: "College",
    Occupational: "Occupational",
    Other: "Other",
  },
  footerRight: {
    PrivacyPolicy: "Privacy Policy",
    Terms: "Terms of Service",
    Help: "Help",
  },
  PopClassShare: {
    ShareSettings: "Share Link",
    ShareYou: "Share your class showcase with friends and family.",
    protects: "Storybird protects your class students' privacy, please set a password for this page visiting.",
    Updated: "Updated",
    ShareLink: "Share link",
    CopyUrl: "Copy Link",
    Copied: "Copied",
  },
  classCreate: {
    CreateClass: "Create a class",
    Start: "Start by creating a class. A class enables you to onboard students and review work.",
    ClassName: "Class name",
    ClassGrade: "Class grade level",
    Primary: "Primary teacher's name",
    Choose: "Choose an end date for this class:",
    You: "You can change this date at any time.",
    Create: "Create Class",
    Cancel: "Cancel",
  },
  joinClass: {
    Enter: "Enter the Class Passcode",
    ENTERTHECLASSPASSCODEHERE: "ENTER THE CLASS PASSCODE HERE",
    Join: "Join the class",
    Incorrect: "Incorrect class passcode format",
    Jointheclass: "Join the class...",
    JoinedSuccessfully: "Joined successfully!",
    HavBeenUsedUp: "All the accounts have been used up, please ask your teacher for help.",
  },
  planHead: {
    Sorry: 'Sorry, your explorer blocked your payment, please click "Continue Payment" to complete the purchase.',
    ContinuePayment: "Continue Payment",
    PayFail: "Pay Fail",
    Thank: "Thank you for your payment!",
  },
  challenge_guides: {
    AllCategories: "All categories",
    Plot: "Plot",
    Structure: "Structure",
    Character: "Character",
    Dialogue: "Dialogue",
    Setting: "Setting",
    Genre: "Genre",
    Craft: "Craft",
    Vocabulary: "Vocabulary",
    Grammar: "Grammar",
    Reference: "Reference",
    Search: "Search",
    all_format: "All Formats",
    Comic: "Comic",
    FlashFiction: "Flash Fiction",
    PictureBook: "Picture Book",
    LongformChapter: "Longform Chapter",
    Poetry: "Poetry",
    AllGenres: "All Genres",
    Life: "Life",
    Humor: "Humor",
    Magic: "Magic",
    Family: "Family",
    Animals: "Animals",
    Friends: "Friends",
    Mystery: "Mystery",
    Romance: "Romance",
    Thriller: "Thriller",
    Adventure: "Adventure",
    SchoolLife: "School Life",
    Fantasy: "Sci-fi/Fantasy",
    AllTopic: "All Topic",
    Pet: "Pet",
    Travel: "Travel",
    Food: "Food",
    Guides: "How-to Guides",
    Build: "Build your skills with tips and instructions to improve your writing.",
    Format: "Format",
  },
  challenge_tag: {
    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
    ThematicCompositions: "Thematic Compositions",
    Children: "Children's Songs",
    LeveledReads: "Leveled Reads",
    Guides: "How-to Guides",
  },
  challenge_info: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
  },
  challengeHome: {
    UNITS: "UNITS",
    CHALLENGES: "CHALLENGES",
    PARTICIPATED: "PARTICIPATED",
    Units: "Units",
    Recently: "Recently added",
    Featured: "Featured",
    Filter: "Filter",
    Clear: "Clear",
    WritingType: "Writing Type",
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",
    AllCategories: "All Categories",
    BasicChallenges: "Basic Challenges",
    BasicChallengesDetails:
      "Become a better writer with writing prompts, lessons, video tutorials, and quizzes. Earn badges and boost your confidence on the page and beyond.",
    CreativeChallenges: "Creative Challenges",
    CreativeChallengesDetails:
      "Explore your creative side with expert writing advice from best-selling authors and industry pros. Learn to write songs, graphic novels, scripts, fantasy, and more. ",
    ThematicCompositions: "Thematic Compositions",
    ThematicCompositionsDetails: "Enjoy creative writing opportunities with thematic threads and seasonal subjects.",
    ChildrensSongs: "Children's Songs",
    ChildrensSongsDetails: "Sing sweet as a songbird to songs of every day words and wonders.",
    LeveledReads: "Leveled Reads",
    LeveledReadsDetails: "Build on developing writing skills by reading Storybird-authored featured writing.",
  },
  challengeUnit: {
    All: "All",
    Narrative: "Narrative",
    Argument: "Argument and Opinion",
    Informational: "Informational",
    Correspondence: "Correspondence",
    Poetry: "Poetry",
    NA: "-NA-",

    BasicWriting: "Basic Writing",
    CreativeWriting: "Creative Writing",
  },
  tools: {
    All: "All",
    PictureBook: "Picture Book",
    LongformStory: "Longform Story",
  },
  printTtabs: {
    PrintableBooks: "Printable Books",
    Cart: "Cart",
    PrintOrder: "Print Order",
  },
  preOrder: {
    Alipay: "Alipay",
    Wechat: "Wechat：",
    FontImage: "Font image",
    Upload: "Upload",
    BackImage: "Back image",
  },
  cart: {
    View: "View print cart",
    Youhaven: "You haven't written any picture/longform books yet",
    startwritingtoday: "start writing today",
  },
  custome_challenge_cover: {
    Writing: "Writing",
    NA: "-NA-",
    View: "View assignment requirements",
  },
  pop_assign_edit: {
    Delete: "Delete",
    Edit: "Edit",
  },
  pop_print_book_type: {
    All: "All",
    PictureBook: "Picture Book",
    LongformStory: "Longform Story",
  },
  pricing: {
    title: "Unlock your creative potential",
    desc_1: "is used by millions of students, teachers, and",
    desc_2: "casual writers. Pick the plan that best meets your needs.",
    compare: {
      free: "Free",
      basic_plan: "Basic Plan",
      tips_free: "Reading & Community Access",
      tips_individual: "Subscription billed monthly (cancel anytime)",
      tips_individual_yearly: "Subscription billed annually",
      tips_school: "Starting at 10 students, billed annually",
      free_7_days: "Free for 7 days",
      get_start: "Get Started",
      month_user: "/month",
      year_user: "/year",
      individual_plan: "Individual Plan",
      buy_now: "Buy Now",
      monthly: "Monthly",
      yearly: "Yearly",
      school_plan: "School Plan",
      school_plan_price_desc: "Special Discount",
      best_deal: "Best Deal",
      core_features: "Core features",
      access_book_you_created: "Access to edit books you've created",
      need_buy_tickets: "Moderation tickets available for purchase",
      class_features: "Class features",
      advanced_features: "Advanced features",
      with_artwork: "Write with unlimited artwork",
      publish_books: "Publish books publicly",
      read_books: "Read books in the library",
      access_guides: "Access “how-to” guides",
      access_resources: "Access courses and resources",
      challenges_to_class: "Assign challenges to class",
      review_submissions: "Review student submissions",
      manage_membership: "Admin dashboard - manage membership licenses",
      tract_performance: "Admin dashboard - Track student performance",
      create_custom_assignments: "Create custom Assignments",
      edit_and_provide_feedback_on_students_work: "Edit and Provide Feedback on students' work",
      interact_with_community: "Interact with Community",
      access_custom: "Access custom stickers, covers, and profiles",
      featured_homepage: "Chances to be featured on homepage",
      review_tickets: "Review tickets and PDF download",
      buy_family_plan: "Discounted Family Memberships Available here!",
      school_plan_free_trial: "Contact us for free trial >>",
    },
    voice_title: "User Testimonials",
    voice: {
      erin_klein: {
        name: "Erin Klein",
        about: "Blogger: Kleinspiration.com",
        text: "“I think I love Storybird just as much as my students enjoy using it. There are no ads on the site, it’s super user-friendly, and Storybird is very engaging for teachers and students.”",
      },
      larry_ferlazzo: {
        name: "Larry Ferlazzo",
        about: "Teacher, author, blogger",
        text: "“Users can choose artwork from a specific artist and then add text to create a storybook... I’m adding the site to The Best Places Where Students Can Write Online.”",
      },
      shannon_miller: {
        name: "  Shannon Miller  ",
        about: "Teacher, librarian, education technology consultant",
        text: "“Storybird can be used by all ages, for all different subjects... Students can rewrite a classic... explain a science concept or retell a historical event.”",
      },
      amy_jussel: {
        name: "Amy Jussel",
        about: "Founder, Executive Director of Shaping Youth",
        text: "“I can’t think of a better way to encourage literary exploration, artsy fun and new media skills to inspire a whole new flock of creative storytellers”",
      },
      drew_buddie: {
        name: "Drew Buddie",
        about: "Learning technologist",
        text: "“Storybird rocks. One of the most engaging apps I’ve used with students in 20 years.”",
      },
      john_mikulski: {
        name: "John Mikulski",
        about: "Middle School Assistant Principal",
        text: "Changed some of my learners' love of writing from 0 to 100. Debra Burson, @debraburson , Year 5/6 teacher, Wellington NZ",
      },
    },
    questions: {
      title: "Any Questions？",
      item_tilte_1: "What is the difference between a Teacher Plan and a School Plan?",
      item_desc_1:
        "Our Teacher Plan includes access for one educator (no student access) and use of our teacher admin tools. The School Plan is customizable in size to include an account for each student/teacher.",
      item_tilte_2: "What payment methods do you accept?",
      item_desc_2:
        "We accept all major credit and debit cards, as well as Paypal. Please contact us to discuss purchase order options.",
      item_tilte_3: "Is my students’ work safe?",
      item_desc_3:
        "Students’ work may be published privately to the teacher or publicly on site. All public stories and comments are reviewed by our professional moderation team. Upon approval, work becomes available for viewing in the Storybird Public Library.",
      has_more: "I have more questions!",
      email_us: "Email us at",
    },
  },
  buy: {
    title: "Place Your Order",
    title_renewal: "Renew Your Order",
    number_limit: "*You cannot purchase a school plan with fewer people than currently available.",
    desc: "Use our tool below to find a price including volume discounts",
    desc_renewal: "Select the number of accounts to receive pricing information.",
    next: "Next",
    pre: "Pre",
    requirements: {
      title: "Account Requirements",
      desc: "Drag to select the number of people (Total number of teachers and students.)",
    },
    information: {
      title: "School Information",
      basic_information: "Basic Information",
      school_name: "School Name",
      country: "Country",
      address: "Address",
      city: "City",
      state: "State",
      zip: "Zip / Postal Code",
      phone: "Phone number",
      teaching_information: "Teaching Information",
      first_name: "First Name",
      last_name: "Last Name",
      job_title: "Job Title",
      school_email: "School Email Address",
      hear_about_us: "How did you hear about us?",
      student_number: "Number of Students",
      start_date: "Activate date for free trial",
    },
    form_rules: {
      name: "Please input school name",
      country: "Please select country",
      address: "Please input address",
      city: "Please input city",
      state: "Please input state",
      zip: "Please input Zip / Postal Code",
      phone: "Please input phone number",
      firstName: "Please input first name",
      lastName: "Please input last name",
      jobTitle: "Please input job title",
      schoolEmail: "Please input school email address",
      hear: "Please select",
    },
    how_use: {
      title: "How will you use Storybird?",
      item_1: "Elementary",
      item_2: "Middle School",
      item_3: "High School",
      item_4: "ESL",
      item_5: "Remote Learning",
      item_6: "Language Arts",
      item_7: "Other",
      talk_us: "Is there additional information you'd like to share?",
    },
    pay_order: {
      title: "Pay order",
      student_accounts: "Accounts",
      original_price: "Original Price",
      discount: "Discount",
      total_price: "Total Price",
      buy_tips:
        "Accounts include student accounts and teacher accounts. For example, if you have 1 teacher and 10 students, you need to buy 11 accounts.",
      buy_tips_1: "The validity period of plan is for a full year.",
    },
    buy_now: "Buy now",
    free_trial_title: "Apply for School Plan Free trial",
    free_trial_received:
      "We have received your application and will get back to you shortly. Thank you for your patience.",
    free_trial_received_unregister:
      "An activation email has been sent to you. Please activate your account within 24 hours to complete the Free Trial application. We have received your application and will get back to you shortly. Thank you for your patience.",
    free_trial_apply: "Apply",
  },
  top_bar: {
    guides: "Tutorials",
    newBook: "New Book",
    chooseBookType: "Choose a book type",
  },
  language: {
    all: "All Language",
    english: "English",
    chinese: "Chinese",
  },
};
export default en;
