const state = {
  title: "",
};

const mutations = {
  SET_MOBILE_TOPBAR_TITLE: (state, title) => {
    state.title = title;
  },
};

const actions = {
  setMobileTopbarTitle({ commit }, title) {
    commit("SET_MOBILE_TOPBAR_TITLE", title);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
