// import { track } from "@amplitude/analytics-browser";
import store from "@/store/index.js";

const LogEvent = {
  EventKey: {
    // 路由
    ROUTE: "stb-route", // 路由：path
    ROUTE_REFERRER: "stb-route-referrer", // referrer来源路由：path、referrer

    // 搜索
    SEARCH_ALL: "stb-search", // 搜索全部：keyword
    SEARCH_ARTWORK: "stb-search-artwork", // 搜索插画：keyword
    SEARCH_ARTWORK_EMPTY: "stb-search-artwork-empty", // 搜索插画为空：keyword
    SEARCH_BOOK: "stb-search-book", // 搜索书本：keyword

    // social分享
    SOCIAL_SHARE_FACEBOOK: "stb-social-facebook", //facebook分享：path、social_id、fbclid
    SOCIAL_SHARE_TWITTER: "stb-social-twitter", //twitter分享：path、social_id
    SOCIAL_SHARE_INSTAGRAM: "stb-social-instagram", //instagram分享：path、social_id

    // 用户
    USER_REGISTER: "stb-register", // 用户注册：username、social_from、path
    USER_PAY: "stb-pay", // 用户点击支付：type、amount、channel
    USER_PAY_SUCCESS: "stb-pay-success", // 用户支付成功：orderno
    USER_PAY_SUCCESS_FAIL: "stb-pay-fail", // 用户支付失败：orderno

    // 写作
    WRITE_ARTWORK: "stb-write-artwork", // 编辑器使用插画：type（picturebook，longformbook，poetry）、artworkid、artistid、id

    // 点击事件
    CLICK_EVENT: "stb-click", // 点击事件：type（topbar、home、buy、read-to-me）、keyword、path

    // 播放视频
    PLAY_VIDEO: "stb-play-video", // 播放视频：type（home-banner）、ID

    // 广告
    STORYBIRD_ADS: "stb-ads", // 广告：ads_id、referrer、path
  },
};

//记录用户事件
LogEvent.trace = function (eventKey, params) {
  if (!eventKey) {
    console.error(`Event key not found`);
    return;
  }

  // 限制上传数据，保证ampl能正常使用
  if (process.env.VUE_APP_ENV_MODE != "prod") {
    // 测试环境不上传
    return;
  }
  if (
    eventKey != LogEvent.EventKey.ROUTE &&
    eventKey != LogEvent.EventKey.ROUTE_REFERRER &&
    eventKey != LogEvent.EventKey.STORYBIRD_ADS
  ) {
    // 只记录路由、referrer和广告
    return;
  }

  const User = store.getters.User;
  if (User && User.uid) {
    params["uid"] = User && User.uid;
  }
  params["member"] = User && User.isMember ? 1 : 0;
  amplitude && amplitude.track(eventKey, params);
};

// 初始化amplitude
export function initAmplitude() {
  amplitude && amplitude.init(process.env.VUE_APP_AMPLITUDE_APIKEY);
}

export default LogEvent;
