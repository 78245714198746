const getters = {
  Best: (state) => state.best.bestList,
  isBestBook: (state) => (bookId) => {
    return state.best.bestList.find((item) => item.StoryBookId == bookId);
  },
  User: (state) => {
    const userInfo = { ...state.user };
    return userInfo;
  },
  isLogin: (state) => state.user.isLogin,
  Basket: (state) => {
    const Basket = { ...state.basket };
    return Basket;
  },
  Notification: (state) => {
    const Notification = { ...state.notification };
    return Notification;
  },
  Plan: (state) => {
    const Plan = { ...state.plan };
    return Plan;
  },
  Class: (state) => {
    const clz = { ...state.class };
    return clz;
  },
  isClassExist: (state) => (openId) => {
    const list = state.class.list || [];
    return list.find((item) => item.OpenId === openId);
  },
  UploadToken: (state) => {
    return state.awstoken.UploadToken;
  },
  UploadArtworkToken: (state) => {
    return state.awstoken.ArtworkToken;
  },
  Global: (state) => {
    const global = { ...state.global };
    return global;
  },
  IsInMobilePlatform: (state) => {
    if (state.platform.isMobile && state.platform.deviceSize === "small") {
      return true;
    }
    return false;
  },
  DevicePlatformType: (state) => {
    return state.platform.devicePlatform;
  },
  DeviceSize: (state) => {
    return state.platform.deviceSize;
  },
  GetMobileTopbarTitle: (state) => {
    return state.mobiletopbar.title;
  },
};
export default getters;
